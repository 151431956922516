import React, { useState } from 'react'
import Navbar from '../Layout/Nav'

import "./bondTables.css"

// import 'slick-carousel/slick/slick.css' 
// import 'slick-carousel/slick/slick-theme.css' 
import { GrNext, GrPrevious } from "react-icons/gr";
import TabNavItem from "./TabNavItem";
import TabContent from "./TabContent"
import PrivateBond from './components/privatebonds/privateBonds'
import GOIBonds from './components/Goibonds/GoiBonds'
import PSUBonds from './components/PSUbonds/PSUBonds'
import TaxFreeBonds from './components/taxFreeBonds/taxFreeBonds'
import StateGurantedBonds from './components/stateGurantedBonds/stateGurantedBonds'

const BondDetails = () => {

  return (
    <div >
      <Navbar />
      <div>
        <PrivateBond />
        <GOIBonds />
        <StateGurantedBonds />
        <PSUBonds />
        <TaxFreeBonds />
      </div>
    </div>
  )


}

export default BondDetails;