import * as React from "react";
import { Spinner } from "react-bootstrap";


export default function CircularLoader({ showCircularLoader }) {
  return (
    <div
      style={{
        top:"50vh",
        left:"50vw",
        position:"fixed",
        opacity:"0.5",
        
      }}
    >
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>

    </div>
  );
}
