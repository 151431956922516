import axios from "axios";
import React, { useEffect, useState } from "react";
import NavBar from "../Layout/Nav";
import "./Marque.css";

export default function Marque() {
  const [data, setData] = useState(
   {}
  );
  const [show, setShow] = useState(false);
  
  const fetchmarque=async()=>{
    try{
      const res=await axios.get(`${process.env.REACT_APP_APIURL}/api/marque`)
    
      setData(res.data.results[0])
    }catch(error){
      console.log(error.response.data)
    }

  }
  useEffect(()=>{
    fetchmarque()
  },[])
  const handleChange = (e) => {
    let temp={id:data.id,text:e.target.value}
    setData(temp);
   
  };
  const handleSubmit = async(e) => {
   
    e.preventDefault();
 
    try{
      const res=await axios.patch(`${process.env.REACT_APP_APIURL}/api/marque/${data.id}/update`,{
        text:data.text
      })
       console.log(res.data)
       fetchmarque()
    }catch(error){
      console.log(error)
    }
    setShow(false);
  };

  return (
    <>
      <NavBar/>
      <h2 className="marque-head">Banner</h2>
      <div className="marque-container">
        <p className="marquee">{data.text}</p>
      </div>
      {show ? (
        <form className="edit-marque" onSubmit={handleSubmit}>
          <input type="text" value={data.text} onChange={handleChange} />
          <button type="submit" className="marque-edit">
            Submit
          </button>
        </form>
      ) : (
        <button className="marque-edit" onClick={() => setShow(true)}>
          Edit
        </button>
      )}
    </>
  );
}
