import Table from "react-bootstrap/Table"
import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';

import EditRow from "./EditRow"


const StateGurantedBonds = ({ bondType }) => {
  const [tableData, setTableData]=useState([])

 

  const [editField, setEditField] = useState({
    coupon: 0.00,
    security:"",
    maturity: "",
    interestPayout: "",
    rating: "",
    minInvestment: "",
    bondType:""
  })
  const [showEditRow, setshowEditRow] = useState(false)

  const [addNewRow, setAddNewRow] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`${process.env.REACT_APP_APIURL}/bond/${bondType}`)
      if (response.ok) {
        const data = await response.json()
        setTableData(data)
      }

    }
    fetchData()
  }, [showEditRow])
 
  return (
    <>
    

      <div className="mt-5 p-3 pt-5" >
        <h1 className="text-center" >State Guranted Bonds </h1>
        
            <>
                <div className='d-flex mb-2 justify-content-between align-items-center'>
                  <Button className=' ' variant="primary" onClick={async () => {
                    await setEditField({
                      "coupon": "","security":"", "maturity": "", "interestPayout": "", "rating": "", "minInvestment": "", "bondType":""
                    });
                    await setAddNewRow(true);
                    setshowEditRow(true);
                  }}>
                    Add new
                  </Button>
                </div>
              <Table striped bordered hover>

                <thead>
                  <tr>
                    <td>
                      Coupon
                    </td>
                    <td>
                      Security
                    </td>

                    <td>
                      Maturity
                    </td>
                    <td>
                      Interest Payout
                    </td>
                    <td>
                      Rating
                    </td>
                    <td>Minimum Investment</td>

                  </tr>
                </thead>
                <tbody>
                  {tableData.map((each, index) => (
                    <tr key={each.id} onClick={async (e) => {
                      await setEditField(tableData[index]);
                      setAddNewRow(false);
                      setshowEditRow(true);
                    }}>
                      <td>{each.coupon}</td>
                      <td>{each.security}</td>
                      <td>{each.maturity}</td>
                      <td>{each.interestPayout}</td>
                      <td>{each.rating}</td>
                      <td>{each.minInvestment}</td>
                     
                    </tr>
                  ))}

                </tbody>
              </Table></>
          
       
        {showEditRow && <EditRow addNewRow={addNewRow} data={editField} showModal={showEditRow} bondType={bondType} closeModal={setshowEditRow} />}
      </div>
    </>

  )
}

export default StateGurantedBonds;  


