import Table from "react-bootstrap/Table"
import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';

import ShriramModal from "./EditRow"
import NavBar from "../../Layout/Nav"


const ShriramTable = ({ depositType }) => {

  const [non_senior_bulk, setNonSeniorBulk] = useState([])
  const [senior_bulk, setSeniorBulk] = useState([])
  const [non_senior_retail, setNonSeniorRetail] = useState([])
  const [senior_retail, setSeniorRetail] = useState([])
  const [allTables, setAllTables] = useState([])

  const [editField, setEditField] = useState({
    tenor: "",
    monthly: "",
    quarterly: "",
    half_yearly: "",
    yearly: "",
    effective_yield: "",
    maturity: "",
    deposit_type: ""
  })
  const [showEditRow, setshowEditRow] = useState(false)

  const [addNewRow, setAddNewRow] = useState(false)


  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`${process.env.REACT_APP_APIURL}/rates/shriram/?deposit_type=senior_bulk`)
      if (response.ok) {
        const data = await response.json()
        setSeniorBulk(data)
      }

    }
    fetchData()
  }, [showEditRow])
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`${process.env.REACT_APP_APIURL}/rates/shriram/?deposit_type=non_senior_bulk`)
      if (response.ok) {
        const data = await response.json()
        setNonSeniorBulk(data)
      }

    }
    fetchData()
  }, [showEditRow])
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`${process.env.REACT_APP_APIURL}/rates/shriram/?deposit_type=senior_retail`)
      if (response.ok) {
        const data = await response.json()
        setSeniorRetail(data)
      }

    }
    fetchData()
  }, [showEditRow])

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`${process.env.REACT_APP_APIURL}/rates/shriram/?deposit_type=non_senior_retail`)
      if (response.ok) {
        const data = await response.json()
        setNonSeniorRetail(data)
      }
    }
    fetchData()
  }, [showEditRow])

  useEffect(() => {
    setAllTables([senior_bulk, non_senior_bulk, senior_retail, non_senior_retail])
  }, [senior_bulk, non_senior_bulk, senior_retail, non_senior_retail])

  return (
    <>
      <NavBar />

      <div className="mt-5 p-3 pt-5" >
        <h1 className="text-center text-primary" >Shriram FD  Rates </h1>
        {
          allTables.map((eachTable, tableIndex) => (
            <>
              {eachTable.length !== 0 &&
                <div className='d-flex mb-2 justify-content-between align-items-center'>
                  <h1 className='text-center'>{eachTable[0].deposit_type}</h1>
                  <Button className=' ' variant="primary" onClick={async () => {
                    await setEditField({
                      "period": "", "monthly": "", "quarterly": "", "half_yearly": "", "yearly": "", "deposit_type": eachTable[0].deposit_type, maturity: "", effective_yield: ""
                    });
                    await setAddNewRow(true);
                    setshowEditRow(true);
                  }}>
                    Add new
                  </Button>
                </div>}
              <Table striped bordered hover>

                <thead>
                  <tr>
                    <td>
                      period
                    </td>

                    <td>
                      monthly
                    </td>
                    <td>
                      quarterly
                    </td>
                    <td>
                      halfyearly
                    </td>
                    <td>yearly</td>
                    <td>effective_yield</td>
                    <td>maturity</td>

                  </tr>
                </thead>
                <tbody>
                  {eachTable.map((each, index) => (
                    <tr key={each.id} onClick={async (e) => {
                      await setEditField(eachTable[index]);
                      setAddNewRow(false);
                      setshowEditRow(true);
                    }}>
                      <td>{each.period}</td>
                      <td>{each.monthly}</td>
                      <td>{each.quarterly}</td>
                      <td>{each.half_yearly}</td>
                      <td>{each.yearly}</td>
                      <td>{each.effective_yield}</td>
                      <td>{each.maturity}</td>
                    </tr>
                  ))}

                </tbody>
              </Table></>
          ))
        }

        {showEditRow && <ShriramModal addNewRow={addNewRow} data={editField} showModal={showEditRow} depositType={depositType} tableName={editField.table_type} closeModal={setshowEditRow} />}
      </div>
    </>

  )
}

export default ShriramTable