import React, { useContext, useState } from 'react'
import {
  Col,
  Container,
  Image,
  Row,
  Card,
  Modal,
  CardDeck,
  CardGroup,
  Form,
} from "react-bootstrap";
import { Button } from "@material-ui/core";
import { AppContext } from "../../context/AppContext";
import axios from 'axios';
import dotenv from "dotenv";
import { PortraitSharp } from '@material-ui/icons';
import { Component } from 'react';
import { render } from '@testing-library/react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./CreateIPO.css";

dotenv.config()
const today = new Date();
export default class NewCreateIPO extends Component {

  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      position: "",
      pdf: "",
      video: "",
      img: "",
      status: "",
      upcoming: [],
      ongoing: [],
      recent: [],
      startDate: new Date(),
      closeDate: new Date(),
      IssueSize: 0,
      IssueSizeCr: 0,
      lotSize: 0

    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.uploadImage = this.uploadImage.bind(this)
    this.convertBase64 = this.convertBase64.bind(this)
    this.handleOpenChange = this.handleOpenChange.bind(this);
    this.handleCloseChange = this.handleCloseChange.bind(this);
    this.uploadPdf = this.uploadPdf.bind(this)

  }
  handleOpenChange(e) {
    this.setState({
      startDate: e.target.value
    })
  }
  handleCloseChange(e) {
    this.setState({
      closeDate: e.target.value
    })
  }

  componentWillReceiveProps(props) {
    this.state = {
      upcoming: props.upcoming,
      ongoing: props.ongoing,
      recent: props.ongoing
    }
  }

  formatISOLocal = () => {
    let d = new Date()
    let z = n => ('0' + n).slice(-2);
    return d.getFullYear() + '-' + z(d.getMonth() + 1) + '-' + z(d.getDate());
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })


  }
  convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  uploadImage = async (e) => {
    const file = e.target.files[0];
    const base64 = await this.convertBase64(file);

    this.setState({ img: base64 })
  };
  uploadPdf = async (e) => {
    const file = e.target.files[0];
    const base64 = await this.convertBase64(file);

    this.setState({ pdf: base64 })
  }
  handleSubmit = async (e) => {
    e.preventDefault()
    let isUpcoming = false, isOngoing = false, isRecent = false;
    const { upcoming, ongoing, recent, ...ipo } = this.state
    if (this.state.status === "upcoming") {

      for (var i = this.state.position - 1; i < upcoming.length; i++) {
        this.props.Updateposition(upcoming[i].id, upcoming[i].position + 1)
      }
      isUpcoming = true
    }
    if (this.state.status === "ongoing") {
      for (var i = this.state.position - 1; i < ongoing.length; i++) {
        this.props.Updateposition(ongoing[i].id, ongoing[i].position + 1)
      }
      isOngoing = true
    }


    if (this.state.status === "recent") {
      for (var i = this.state.position - 1; i < recent.length; i++) {
        this.props.Updateposition(recent[i].id, recent[i].position + 1)
      }
      isRecent = true
    }
    let newIpo = {
      ...ipo,
      upcoming: isUpcoming,
      ongoing: isOngoing,
      recent: isRecent
    }

    const res = await fetch(`${process.env.REACT_APP_APIURL}/api/ipo`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${this.context.user.token}`,
        "content-type": "Application/json"
      },
      body: JSON.stringify(newIpo)

    })
    if (res.ok) {
      alert("New Ipo created")
    } else {
      alert("please try again")
    }
    this.props.fetchIPO()


    this.props.onHide();



  }
  render() {
    return (

      <Modal
        {...this.props}
        size="md"
        // className="modal-content"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Create IPO</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>
          <Form style={{ alignItems: "left" }} onSubmit={this.handleSubmit}>
            <Form.Group as={Row} className="mb-3" style={{ textAlign: "left" }}>
              <Form.Label column sm="5">Name of the Company</Form.Label>
              <Col sm="7">
                <Form.Control
                  type="text"
                  placeholder="Enter Name of the Company"
                  required
                  name="name"
                  onChange={this.handleInputChange}
                />
              </Col>

            </Form.Group>

            <Form.Group as={Row} className="mb-3" style={{ textAlign: "left" }}>
              <Form.Label column sm="5">Open Date</Form.Label>
              <Col sm="7">
                <input type="date"
                  // selected={this.state.startDate}
                  onChange={this.handleOpenChange}
                  name="startDate"
                  dateFormat="dd/MM/yyyy"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" style={{ textAlign: "left" }}>
              <Form.Label column sm="5">Close Date</Form.Label>
              <Col sm="7">
                <input type="date"
                  // minDate={new Date(this.state.startDate)}
                  // selected={this.state.closeDate}
                  // min={this.formatISOLocal()}
                  onChange={this.handleCloseChange}
                  name="closeDate"
                  dateFormat="dd/MM/yyyy"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" style={{ textAlign: "left" }}>
              <Form.Label column sm="5">Issue Size</Form.Label>
              <Col sm="7">
                <Form.Control
                  type="text"
                  placeholder="Enter Issue Size"
                  required
                  name="issueSize"
                  onChange={this.handleInputChange}
                />
              </Col>

            </Form.Group>
            <Form.Group as={Row} className="mb-3" style={{ textAlign: "left" }}>
              <Form.Label column sm="5">Issue Size(Cr)</Form.Label>
              <Col sm="7">
                <Form.Control
                  type="text"
                  placeholder="Enter Issue Size(Cr)"
                  required
                  name="issueSizeCr"
                  onChange={this.handleInputChange}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" style={{ textAlign: "left" }}>
              <Form.Label column sm="5">Lot Size</Form.Label>
              <Col sm="7">
                <Form.Control
                  type="text"
                  placeholder="Enter Lot Size"
                  required
                  name="lotSize"
                  onChange={this.handleInputChange}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" style={{ textAlign: "left" }}>
              <Form.Label column sm="5">Position</Form.Label>
              <Col sm="7">
                <Form.Control
                  type="text"
                  placeholder="Enter position"
                  required
                  name="position"
                  onChange={this.handleInputChange}
                />
              </Col>

            </Form.Group>
            <Form.Group as={Row} className="mb-3" style={{ textAlign: "left" }}>
              <Form.Label column sm="5">PDF link</Form.Label>
              <Col sm="7">
                <Form.Control
                  type="file"
                  placeholder="Upload PDF"
                  accept="pdf/*"
                  onChange={(e) => {
                    this.uploadPdf(e);
                  }}
                  id="pdf"
                  required
                />
              </Col>

            </Form.Group>
            <Form.Group as={Row} className="mb-3" style={{ textAlign: "left" }}>
              <Form.Label column sm="5">Video link</Form.Label>
              <Col sm="7">
                <Form.Control
                  type="text"
                  placeholder="Enter Youtube Video link"
                  // required
                  name="video"
                  onChange={this.handleInputChange}

                />
              </Col>
            </Form.Group>
            <Form.Group className="mb-3" style={{ textAlign: "left" }}>
              <Form.Label>Company Logo</Form.Label>
              <Form.Control
                type="file"
                placeholder="Upload Company Logo"
                accept="image/*"
                onChange={(e) => {
                  this.uploadImage(e);
                }}
                id="imgBox"
                required
              />
            </Form.Group>

            <Form.Group style={{ textAlign: "left" }} className="form-radios mb-3" required >
              <div className="create-radio">
                <Form.Check type="radio" id="upcoming" name="status" value="upcoming" onChange={this.handleInputChange} />
                <Form.Label>Upcoming</Form.Label>
              </div>
              <div>
                <Form.Check type="radio" id="ongoing" name="status" value="ongoing" onChange={this.handleInputChange} />
                <Form.Label>Ongoing</Form.Label>
              </div>
              <div>
                <Form.Check type="radio" id="recent" name="status" value="recent" onChange={this.handleInputChange} />
                <Form.Label>Recent</Form.Label>
              </div>
            </Form.Group>
            <Button variant="contained" color="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>



    )

  }

}

