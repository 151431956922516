import React from 'react'
import Navbar from '../Layout/Nav'
import { Modal,Button,Form,Col } from 'react-bootstrap';
import './Advisor.css'

export default function NewAdvisor() {
    const [modalShow, setModalShow] = React.useState(false);
    const handleClick = () => {
        setModalShow(true);
    }
    function MyVerticallyCenteredModal(props) {
        return (
          <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Adhvaith Ramesh
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Row>
                  <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value="adhvaithramesh25@gmail.com"
                      readOnly
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="formGridPhone">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      name="phoneno"
                      value="9686662439"
                      readOnly
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="formGridPhone">
                    <Form.Label>Resume</Form.Label>
                    <iframe src="https://drive.google.com/file/d/17VWnBiJjR27oSopUBs3Xw6mAzXfcaomY/preview" height="480" allow="autoplay" width="100%"></iframe>
                  </Form.Group>
                </Form.Row>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
          </Modal>
        );
      }
    return (
        <div>
            <Navbar />
            <div>
            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
                <h2 style={{marginTop:"5rem"}}>Advisor Onboarding</h2>
                <div className="newadd-cont">
                {[1,2,3,4,5,6,7,8,9].map((list,index)=>(
                                        <div className="newadd-box">
                                        <i className="fa-eye fas" onClick={()=>handleClick()}/>
                                        <h4>Adhvaith Ramesh</h4>
                                        <div>
                                            <div>
                                                <input type="radio" name="select" value="accept" id="accept"/>
                                                <label htmlFor="accept">Accept</label>
                                            </div>
                                            <div>
                                                <input type="radio" name="select" value="reject" id="reject"/>
                                                <label htmlFor="reject">Reject</label>
                                            </div>
                                        </div>
                                    </div>
                ))}
                </div>
            </div>
        </div>
    )
}
