import React, { Component } from "react";

// import Button from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import dotenv from "dotenv";
import { AppContext } from "../../context/AppContext";
import "./Auth.css";
import { red } from "@material-ui/core/colors";
dotenv.config();
const api = process.env.REACT_APP_APIURL;

export default class Login extends Component {
	static contextType = AppContext;
	constructor(props) {
		super(props);

		this.state = {
			email: "",
			password: "",
			error: null,
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.login = this.login.bind(this);
	}

	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};
	login = async () => {
		let { error, ...rest } = this.state;
		this.context.dispatch({ type: "LOGIN_START" });

		try {
			const res = await axios.post(`${api}/api/login`, rest);

			if (res.data.access !== "admin") {
				toast.error("You are Not Authorized to use this Website", {
					position: "bottom-center",
					autoClose: 5000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			} else {
				this.context.dispatch({ type: "LOGIN_SUCESS", payload: res.data });
				if (res.data) {
					this.setState({
						email: "",
						password: "",
					});

					localStorage.setItem("user", JSON.stringify(res.data));
					this.props.history.push("/");
				}
			}
		} catch (error) {
			if (error.response) {
				this.context.dispatch({
					type: "LOGIN_FAILURE",
					payload: error.response.data,
				});
				this.setState({
					error: this.context.error.message,
				});
			}
		}
	};

	handleSubmit = (e) => {
		e.preventDefault();

		this.login();
	};
	render() {
		return (
			<div className="login-main">
				<img src="/NishkaEra_Logo.jpg" alt="Nishkaera" />
				<form className="login-cont" onSubmit={this.handleSubmit}>
					<div>
						<label for="username">Username</label>
						<input
							type="text"
							id="username"
							name="email"
							onChange={this.handleChange}
						/>
					</div>
					<div>
						<label for="password">Password</label>
						<input
							type="password"
							id="password"
							name="password"
							onChange={this.handleChange}
						/>
					</div>
					{this.state.error && (
						<p style={{ color: "red" }}>{this.state.error}</p>
					)}
					<button type="submit">Login</button>
				</form>

			</div>
		);
	}
}
