import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React, { useState, useEffect } from 'react';
import { useContext } from "react"
import { AppContext } from "../../../context/AppContext";

const NcdModal = ({ data, showModal, closeModal, addNewRow, fetchData }) => {
  const { user } = useContext(AppContext)
  const [editField, setEditField] = useState({})
  // const [show, setShow] = useState(showModal);
  const handleClose = () => closeModal(false);
  // const handleShow = () => setShow(true);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setEditField(data)
  }, [])

  const submitData = async () => {
    setLoading(true)

    if (addNewRow) {
      const res = await fetch(`${process.env.REACT_APP_APIURL}/ncd/create/`, {
        method: "POST",
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${user.token}`
        },
        body: JSON.stringify(editField)
      })
      setLoading(false)
      console.log(res)
      const data = await res.json()
      console.log(data)
      if (res.ok) {
        closeModal(false)
        fetchData()
      } else {

        alert("unable to save please try again")
      }
    }
    else {
      const res = await fetch(`${process.env.REACT_APP_APIURL}/ncd/${editField.ncdID}/update/`, {
        method: "POST",
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${user.token}`
        },
        body: JSON.stringify(editField)
      })
      setLoading(false)
      console.log(res)
      const data = await res.json()
      console.log(data)
      if (res.ok) {
        closeModal(false)
        fetchData()
      } else {

        alert("unable to save please try again")
      }
    }


  }

  const deleteRow = async () => {
    setLoading(true)
    // {"id":1,"tenure":"15","cumulative":"6.40","monthly":"6.22","quarterly":"6.25","halfyearly":"6.40","annual":"6.40"}
    const res = await fetch(`${process.env.REACT_APP_APIURL}/ncd/${editField.ncdID}/delete/`, {
      method: "POST",
      headers: {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${user.token}`
      },
      body: JSON.stringify(editField)
    })
    setLoading(false)
    console.log(res)
    const data = await res.json()
    console.log(data)


    if (res.ok) {
      console.log("deleted")
      closeModal(false)
      fetchData()
    } else {
      alert("Please try again")
    }
    setLoading(false)
  }

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{addNewRow ? "adding new row " : "Editing row "}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-floating mb-2">
            <input type="text" onChange={(e) => {
              setEditField((prev) => ({ ...prev, issuer: e.target.value }))
            }} value={editField.issuer} class="form-control" id="issuer" />
            <label for="issuer">issuer</label>
          </div>

          <div class="form-floating mb-2">
            <input type="text" onChange={(e) => {
              setEditField((prev) => ({ ...prev, rating: e.target.value }))
            }} value={editField.rating} class="form-control" id="rating" />
            <label for="rating">rating</label>
          </div>
          <div class="form-floating mb-2">
            <input type="text" onChange={(e) => {
              setEditField((prev) => ({ ...prev, coupon: e.target.value }))
            }} value={editField.coupon} class="form-control" id="coupon" />
            <label for="coupon">coupon</label>
          </div>
          <div class="form-floating mb-2">
            <input type="date" onChange={(e) => {
              setEditField((prev) => ({ ...prev, maturityDate: e.target.value }))
            }} value={editField.maturityDate} class="form-control" id="maturityDate" />
            <label for="maturityDate">maturityDate</label>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={loading} variant={loading ? "success" : "primary"} onClick={submitData}>
            {loading ? "processing request..." : "save"}
          </Button>
          {
            !addNewRow && <Button disabled={loading} variant="danger" onClick={deleteRow}>
              {loading ? "processing request..." : "delete"}
            </Button>
          }

        </Modal.Footer>
      </Modal>
    </>
  );
}

export default NcdModal