import React, { useState, useEffect, useContext } from "react";
import Jumbotron from "react-bootstrap/Jumbotron";
import {
  Col,
  Container,
  Image,
  Row,
  Card,
  Modal,

  Button,
} from "react-bootstrap";

// import {Button} from "react-bootstrap"
import "./IPO.css";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import EditIPO from "../ActionsIPO/EditIPO";

import NavBar from "../Layout/Nav";

import axios from "axios";
import dotenv from "dotenv";
import { AppContext } from "../../context/AppContext";

import NewCreateIPO from "../ActionsIPO/NewCreateIPO";

dotenv.config();
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    // maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
}));

export default function IPO() {
  const { user } = useContext(AppContext);
  const [modalShow, setModalShow] = React.useState(false);
  const [title, setTitle] = useState("");
  const [ppt, setPpt] = useState("");
  const [createShow, setCreateShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [editCard, setEditCard] = useState({});
  const [status, setStatus] = useState("upcoming");

  ///
  const [edit, setEdit] = useState(false);
  const [dragble, setDragble] = useState(false);
  const [dragId, setDragId] = useState();

  const mTop = {
    margin: "2% 0%",
  };
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const [upcoming, setUpcoming] = useState([]);
  const [ongoing, setOngoing] = useState([]);
  const [recent, setRecent] = useState([]);

  const fetchIPO = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_APIURL}/api/ipo`, {
        method: "GET"
      });
      console.log(res)
      let result = await res.json();
      console.log(result)
      result = result.results;

      let upcomings = result.filter((item) => item.status === "upcoming");
      upcomings.sort((a, b) => a.position - b.position);
      setUpcoming(upcomings);

      let ongoings = result.filter((item) => item.status === "ongoing");
      ongoings.sort((a, b) => a.position - b.position);
      setOngoing(ongoings);
      let recents = result.filter((item) => item.status === "recent");
      recents.sort((a, b) => a.position - b.position);
      setRecent(recents);

      console.log(upcoming, recent, ongoing)
    } catch (error) { }
  };
  useEffect(() => {
    fetchIPO();
  }, []);
  //   const handleUpcomingDelete = (id) => {
  //       const newItems = upcoming.filter(item=>item.id !== id);
  //       setUpcoming(newItems)
  //   }
  //   const handleOngoingDelete = (id) => {
  //     const newItems = ongoing.filter(item=>item.id !== id);
  //     setOngoing(newItems)
  // }
  // const handleRecentDelete = (id) => {
  //     const newItems = recent.filter(item=>item.id !== id);
  //     setRecent(newItems)
  // }

  const Updateposition = async (id, position) => {
    console.log(id, position);
    try {
      const res = await axios.patch(
        `${process.env.REACT_APP_APIURL}/api/ipo/${id}/update/position`,
        {
          position: position,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      fetchIPO();
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };
  const handledelete = async (item, id) => {
    if (item.status === "upcoming") {
      for (var i = item.position; i < upcoming.length; i++) {
        Updateposition(upcoming[i].id, upcoming[i].position - 1);
      }
    }
    if (item.status === "ongoing") {
      for (var i = item.position; i < ongoing.length; i++) {
        Updateposition(ongoing[i].id, ongoing[i].position - 1);
      }
    }
    if (item.status === "recent") {
      for (var i = item.position; i < recent.length; i++) {
        Updateposition(recent[i].id, recent[i].position - 1);
      }
    }

    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_APIURL}/api/ipo/${id}`,

        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      if (res) {
        fetchIPO();
      }
    } catch (error) { }
  };

  const handledragedit = () => {
    setEdit(!edit);
    setDragble(!dragble);
  };
  const handleupcomingsubmit = () => {
    for (var i = 0; i < upcoming.length; i++) {
      Updateposition(upcoming[i].id, upcoming[i].position);
    }

    setEdit(false);
    setDragble(false);
  };
  const handleongoingsubmit = () => {
    for (var i = 0; i < ongoing.length; i++) {
      Updateposition(ongoing[i].id, ongoing[i].position);
    }

    setEdit(false);
    setDragble(false);
  };
  const handlerecentsubmit = () => {
    for (var i = 0; i < recent.length; i++) {
      Updateposition(recent[i].id, recent[i].position);
    }

    setEdit(false);
    setDragble(false);
  };
  const handleDrag = (ev) => {
    setDragId(ev.currentTarget.id);
  };
  const handleupcomingDrop = (ev) => {
    let dragid = dragId;
    const dragcard = upcoming.find((card) => card.id === dragid);
    const dropcard = upcoming.find((card) => card.id === ev.currentTarget.id);

    const dragCardOrder = dragcard.position;
    const dropCardOrder = dropcard.position;
    const newUpcomings = upcoming.map((card) => {
      if (card.id === dragId) {
        card.position = dropCardOrder;
      }
      if (card.id === ev.currentTarget.id) {
        card.position = dragCardOrder;
      }

      return card;
    });

    console.log(newUpcomings);

    setUpcoming(newUpcomings.sort((a, b) => a.position - b.position));
  };
  const handleongoingDrop = (ev) => {
    let dragid = dragId;
    const dragcard = ongoing.find((card) => card.id === dragid);
    const dropcard = ongoing.find((card) => card.id === ev.currentTarget.id);

    const dragCardOrder = dragcard.position;
    const dropCardOrder = dropcard.position;
    const newongoings = ongoing.map((card) => {
      if (card.id === dragId) {
        card.position = dropCardOrder;
      }
      if (card.id === ev.currentTarget.id) {
        card.position = dragCardOrder;
      }

      return card;
    });

    console.log(newongoings);

    setOngoing(newongoings.sort((a, b) => a.position - b.position));
  };
  const handlerecentDrop = (ev) => {
    let dragid = dragId;
    const dragcard = recent.find((card) => card.id === dragid);
    const dropcard = recent.find((card) => card.id === ev.currentTarget.id);

    const dragCardOrder = dragcard.position;
    const dropCardOrder = dropcard.position;
    const newrecents = recent.map((card) => {
      if (card.id === dragId) {
        card.position = dropCardOrder;
      }
      if (card.id === ev.currentTarget.id) {
        card.position = dragCardOrder;
      }

      return card;
    });

    console.log(newrecents);

    setRecent(newrecents.sort((a, b) => a.position - b.position));
  };

  const handleClick = (title, ppt) => {
    setTitle(title);
    setPpt(ppt);
    setModalShow(true);
  };
  const handleEditClick = (card, status) => {
    setEditCard(card);
    setEditShow(true);
    console.log(card);
    setStatus(status);
  };
  const base64toBlob = (data) => {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);

    const bytes = atob(base64WithoutPrefix);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: 'application/pdf' });
  };

  const ReadablePdf = (pdf) => {
    const blob = base64toBlob(pdf);
    const url = URL.createObjectURL(blob);
    return url
  }


  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>
          <iframe
            src={ppt}
            frameborder="0"
            allowfullScreen="true"
            mozallowfullscreen="true"
            webkitallowfullscreen="true"
            className="ppt-el"
            title="ipo-ppt"
            allow="autoplay"
          ></iframe>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Go Back</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  return (

    <div className="App">
      {console.log(upcoming, recent, ongoing)}
      <NavBar />
      <Jumbotron className="container-fluid">
        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />

        <h1 style={mTop}>IPO Watch</h1>
        <Button
          variant="danger"
          style={{ marginBottom: "3%" }}
          onClick={() => setCreateShow(true)}
        >
          Create IPO
        </Button>

        <NewCreateIPO
          show={createShow}
          onHide={() => setCreateShow(false)}
          upcoming={upcoming}
          ongoing={ongoing}
          recent={recent}
          delete
          Updateposition={Updateposition}
          fetchIPO={fetchIPO}
        />

        {/* <CreateIPO show={createShow} onHide={() => setCreateShow(false)} upcoming={upcoming} ongoing={ongoing} recent={recent} delete Updateposition={Updateposition} fetchIPO={fetchIPO} /> */}
        {editShow && <EditIPO
          show={editShow}
          onHide={() => setEditShow(false)}
          details={editCard}
          fetchIPO={fetchIPO}
          upcoming={upcoming}
          ongoing={ongoing}
          recent={recent}
          delete
          Updateposition={Updateposition}
          status={status}
        />}


        <div className={classes.root}>
          <AppBar position="static" color="default" className="ipo-tab">
            <Tabs
              value={value}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="on"
              indicatorColor="primary"
              textColor="primary"
              aria-label="scrollable force tabs example"
              initialSelectedIndex={1}
            >
              <Tab label="Upcoming" {...a11yProps(0)} />
              <Tab label="Ongoing" {...a11yProps(1)} />
              <Tab label="Recent" {...a11yProps(2)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <Container style={{ backgroundColor: edit ? "gray" : "white" }}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  style={{
                    padding: "5px",
                    cursor: "pointer",
                    borderRadius: "30px",
                    width: "80px",
                    display: "flex",
                    border: "1px solid black",
                    marginTop: "10px",
                  }}
                  onClick={handledragedit}
                >
                  <EditIcon />
                  <p style={{ margin: "0px" }}>edit</p>
                </div>
              </div>

              <Row>
                {upcoming.length > 0 ? (
                  upcoming.map((item) => (
                    <Col
                      draggable={dragble}
                      lg={4}
                      md={6}
                      sm={12}
                      key={item.id}
                    >
                      <Card
                        draggable={dragble}
                        id={item.id}
                        onDragOver={(ev) => ev.preventDefault()}
                        onDragStart={handleDrag}
                        onDrop={handleupcomingDrop}
                        className="ipo-card"
                      >
                        <EditIcon
                          onClick={() => handleEditClick(item, "upcoming")}
                        />
                        <DeleteIcon
                          onClick={() => handledelete(item, item.id)}
                        />
                        <Image
                          draggable={dragble}
                          src={item.logo}
                          alt={item.name}
                          className="ipo-img"
                        />
                        {/* <embed src={item.pdf} ></embed> */}
                        {/* <Viewer fileUrl={ReadablePdf(item.pdf)} /> */}
                        <iframe title="pdf" src={item.pdf} />
                        <p draggable={dragble} className="ipo-card-title">
                          {item.name}
                        </p>

                        {item.video !== "NA" && (
                          <p
                            draggable={dragble}
                            className="ipo-card-link"
                            onClick={() => handleClick(item.name, item.video)}
                          >
                            Know more
                          </p>
                        )}
                      </Card>
                    </Col>
                  ))
                ) : (
                  <p>No results found. You can come back later</p>
                )}
              </Row>
            </Container>
            {edit && (
              <Button
                style={{ margin: "10px" }}
                variant="primary"
                onClick={handleupcomingsubmit}
              >
                submit
              </Button>
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Container style={{ backgroundColor: edit ? "gray" : "white" }}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  style={{
                    padding: "5px",
                    cursor: "pointer",
                    borderRadius: "30px",
                    width: "80px",
                    display: "flex",
                    border: "1px solid black",
                    marginTop: "10px",
                  }}
                  onClick={handledragedit}
                >
                  <EditIcon />
                  <p style={{ margin: "0px" }}>edit</p>
                </div>
              </div>
              <Row>
                {ongoing.length > 0 ? (
                  ongoing.map((item) => (
                    <Col
                      draggable={dragble}
                      lg={4}
                      md={6}
                      sm={12}
                      key={item.id}
                    >
                      <Card
                        draggable={dragble}
                        id={item.id}
                        onDragOver={(ev) => ev.preventDefault()}
                        onDragStart={handleDrag}
                        onDrop={handleongoingDrop}
                        className="ipo-card"
                      >
                        <EditIcon
                          onClick={() => handleEditClick(item, "ongoing")}
                        />
                        <DeleteIcon
                          onClick={() => handledelete(item, item.id)}
                        />

                        <Image
                          draggable={dragble}
                          src={item.logo}
                          alt={item.name}
                          className="ipo-img"
                        />
                        <p draggable={dragble} className="ipo-card-title">
                          {item.name}
                        </p>
                        {item.video !== "NA" && (
                          <p
                            className="ipo-card-link"
                            onClick={() => handleClick(item.name, item.video)}
                          >
                            Know more
                          </p>
                        )}
                      </Card>
                    </Col>
                  ))
                ) : (
                  <p>No results found. You can come back later</p>
                )}
              </Row>
              {edit && (
                <Button
                  style={{ margin: "10px" }}
                  variant="primary"
                  onClick={handleongoingsubmit}
                >
                  submit
                </Button>
              )}
            </Container>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Container style={{ backgroundColor: edit ? "gray" : "white" }}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  style={{
                    padding: "5px",
                    cursor: "pointer",
                    borderRadius: "30px",
                    width: "80px",
                    display: "flex",
                    border: "1px solid black",
                    marginTop: "10px",
                  }}
                  onClick={handledragedit}
                >
                  <EditIcon />
                  <p style={{ margin: "0px" }}>edit</p>
                </div>
              </div>
              <Row>
                {recent.length > 0 ? (
                  recent.map((item) => (
                    <Col
                      draggable={dragble}
                      lg={4}
                      md={6}
                      sm={12}
                      key={item.id}
                    >
                      <Card
                        draggable={dragble}
                        id={item.id}
                        onDragOver={(ev) => ev.preventDefault()}
                        onDragStart={handleDrag}
                        onDrop={handlerecentDrop}
                        className="ipo-card"
                      >
                        <EditIcon
                          onClick={() => handleEditClick(item, "recent")}
                        />
                        <DeleteIcon
                          onClick={() => handledelete(item, item.id)}
                        />

                        <Image
                          draggable={dragble}
                          src={item.logo}
                          alt={item.name}
                          className="ipo-img"
                        />
                        <p draggable={dragble} className="ipo-card-title">
                          {item.name}
                        </p>
                        {item.video !== "NA" && (
                          <p
                            className="ipo-card-link"
                            onClick={() => handleClick(item.name, item.video)}
                          >
                            Know more
                          </p>
                        )}
                      </Card>
                    </Col>
                  ))
                ) : (
                  <p>No results found. You can come back later</p>
                )}
              </Row>
              {edit && (
                <Button
                  style={{ margin: "10px" }}
                  variant="primary"
                  onClick={handlerecentsubmit}
                >
                  submit
                </Button>
              )}
            </Container>
          </TabPanel>
        </div>
      </Jumbotron>
    </div>
  );
}
