import React, { useEffect, useContext } from 'react'
import NavBar from '../Layout/Nav'
import { useState } from "react";
import { GoLocation } from "react-icons/go";
import { BiTimeFive, BiEdit } from "react-icons/bi";
import { AiOutlineDelete } from "react-icons/ai";
import { toast } from "react-toastify";
import { GrFormView } from 'react-icons/gr'

import { AppContext } from '../../context/AppContext';

import './index.css'

const availablePositions = [
    {
        card: {
            type: "Sales and marketing",
            title: "Sales Manager (Fixed income)",
            description:
                "We are looking for highly motivated individual for the role of Sales Manager to help us build up our business activities and manage sales of financial products and services across India.",
            location: "Gurugram",
            employmentType: "Full Time",
        },
        id: 1,
        jobType: "Sales",
        title: "Human Resource Intern",
        vision:
            "We foresee a future where every individual of our society is fully enabled through Nishkaera platform to be able to make fit-for-purpose financial decisions based on the comprehensive knowledgebase and tools.",
        about:
            "Nishkaera is a financial services company, which offers one-stop financial solutions to its customers on a Pan-Indian basis. These products and services include all types of financial products, webinars on financial literacy, investment advisory services, financial analysis, etc which are offered on an online platform developed using best-in-class technology. To know more about the company please visit www.nishkaera.com.",
        qualifications: [
            "The candidate must have excellent communication skills",
            "Should be from HR educational background.",
        ],
        responsibilities: [
            "Posting job advertisements to job boards and social media platforms.",
            "Organizing interviews with shortlisted candidates.",
            "Removing job advertisements from job boards and social media platforms once vacancies have been filled.",
            "Preparing and sending offer and rejection letters or emails to candidates.",
            "Coordinating new hire orientations.",
            "Computer literacy (MS Office applications, in particular",
            "Strong phone, email and in-person communication skills.",
        ],
        employmentType: ["Full time", "Internship", "Fresher"],
        location: "Sector 48, Gurugram.",
    },
    {
        card: {
            type: "Sales and marketing",
            title: "Sales Manager (Fixed income)",
            description:
                "We are looking for highly motivated individual for the role of Sales Manager to help us build up our business activities and manage sales of financial products and services across India.",
            location: "Gurugram",
            employmentType: "Full Time",
        },
        id: 2,
        jobType: "Sales",
        title: "Financial Analyst Intern",
        vision:
            "We foresee a future where every individual of our society is fully enabled through Nishkaera platform to be able to make fit-for-purpose financial decisions based on the comprehensive knowledgebase and tools.",
        about:
            "Nishkaera is a financial services company, which offers one-stop financial solutions to its customers on a Pan-Indian basis. These products and services include all types of financial products, webinars on financial literacy, investment advisory services, financial analysis, etc which are offered on an online platform developed using best-in-class technology. To know more about the company please visit www.nishkaera.com.",
        qualifications: [
            "Candidate who has finance graduates or pursuing graduation / post-graduation in Finance/ Accounts will be eligible",
            "Candidate who will to start the internship on immediate basis.",
            "Candidates residing in Gurgaon will be preferred",
        ],
        responsibilities: [
            "Work on the analysis of IPO/results/ sector etc.",
            "Attend the investors call and various other reports. ",
            "Work on financial modeling. ",
            "Work on the preparation of reports/presentations, etc.",
            "Accounting software and other accounts related.",
            "Competitor Analysis",
        ],
        employmentType: ["Full time", "Internship"],
        location: "Sector 48, Gurugram.",
    },
    {
        card: {
            type: "Sales and marketing",
            title: "Sales Manager (Fixed income)",
            description:
                "We are looking for highly motivated individual for the role of Sales Manager to help us build up our business activities and manage sales of financial products and services across India.",
            location: "Gurugram",
            employmentType: "Full Time",
        },
        id: 3,
        jobType: "Sales",
        title: "Financial Analyst Intern",
        vision:
            "We foresee a future where every individual of our society is fully enabled through Nishkaera platform to be able to make fit-for-purpose financial decisions based on the comprehensive knowledgebase and tools.",
        about:
            "Nishkaera is a financial services company, which offers one-stop financial solutions to its customers on a Pan-Indian basis. These products and services include all types of financial products, webinars on financial literacy, investment advisory services, financial analysis, etc which are offered on an online platform developed using best-in-class technology. To know more about the company please visit www.nishkaera.com.",
        qualifications: [
            "Candidate who has finance graduates or pursuing graduation / post-graduation in Finance/ Accounts will be eligible",
            "Candidate who will to start the internship on immediate basis.",
            "Candidates residing in Gurgaon will be preferred",
        ],
        responsibilities: [
            {
                title: "Digital Marketing Executive",
                vision:
                    "We foresee a future where every individual of our society is fully enabled through Nishkaera platform to be able to make fit-for-purpose financial decisions based on the comprehensive knowledgebase and tools.",
                about:
                    "Nishkaera is a financial services company, which offers one-stop financial solutions to its customers on a Pan-Indian basis. These products and services include all types of financial products, webinars on financial literacy, investment advisory services, financial analysis, etc which are offered on an online platform developed using best-in-class technology. To know more about the company please visit www.nishkaera.com.",
                qualifications: [
                    "The ideal candidate should have 3+ years of experience in digital marketing, experience in paid marketing SEO etc.",
                    "Google Analytics Professional Certification.",
                    "Ability to understand key performance indicators and measurements impacting efficiency and quality.",
                    "Ability to work independently and work in a collaborative environment",
                    "Self-motivated with a results-driven approach",
                ],
                responsibilities: [
                    "Measure and report on the performance of all digital marketing and ad campaigns using web analytics tools to monitor site traffic and ad performance.",
                    "Plans and executes all web, SEO/SEM, database marketing, email, social media, and display advertising campaigns.",
                    "Understand and stay current on industry trends, competitive landscape, and internal product development efforts-Google AdWords, Facebook, Twitter, Pinterest & Instagram is mandatory.",
                    "Designs, builds, and maintains our social media presence.",
                    "Brainstorms new and creative growth strategies through digital marketing.",
                ],
                jobType: ["Full time"],
                location: "Sector 48, Gurugram.",
            },
        ],
        employmentType: ["Full time"],
        location: "Sector 48, Gurugram.",
    },
];

const JOB_TYPES = {
    all: "ALL",
    sales: "SALES",
    webDevelopment: "WEB DEVELOPMENT",
    digitalMarketing: "DIGITAL MARKETING",
    hr: "HR",
    accounts: "ACCOUNTS",
    legal: "LEGAL",
};

const activeJobButton =
    "active-job-btn job-btn";
const otherButtons =
    "job-btn";


const Career = () => {
    const { user } = useContext(AppContext)
    const [availablePositions, setAvailablePositions] = useState([])
    const [filteredJobs, setFilteredJobs] = useState([])
    const fetchData = async () => {
        const response = await fetch(`${process.env.REACT_APP_APIURL}/career`, {
            method: "GET"
        })
        const data = await response.json()

        if (response.ok) {
            setAvailablePositions(data)
            if (activeJob === "ALL") {
                setFilteredJobs(availablePositions)
            } else {
                const temp = availablePositions.filter((each) => (
                    each.job_group.toLocaleLowerCase().includes(activeJob.toLocaleLowerCase())
                ))
                setFilteredJobs(temp)
            }
        } else {
            setAvailablePositions([])
            toast.success('Something went wrong ...!!', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    }

    useEffect(() => {
        fetchData()
    }, [])


    const [activeJob, setActiveJob] = useState(JOB_TYPES.all);

    const onChangeJobType = (job) => {
        setActiveJob(job);
        if (job === "ALL") {
            setFilteredJobs(availablePositions)
        } else {
            const temp = availablePositions.filter((each) => (
                each.job_group.toLocaleLowerCase().includes(job.toLocaleLowerCase())
            ))

            setFilteredJobs(temp)
        }

    };

    const deleteJobApplication = async (id) => {
        const response = await fetch(`${process.env.REACT_APP_APIURL}/career/${id}`, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        })
        if (response.ok) {
            toast.success('deleted!!', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            fetchData()
        } else {
            toast.error('unable to delete!!', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }

    }

    return (
        <div className="mt-5 pt-3">
            <NavBar />
            <h1 className='job-main-heading' >Job Opportunities</h1>
            <div className='mb-4 ' >
                <a href='/career/create-job' className='job-btn text-decoration-none' >Create New Job</a>

            </div>
            <div className="main-btn-container">
                <button
                    className={
                        activeJob === JOB_TYPES.all ? activeJobButton : otherButtons
                    }
                    onClick={() => {
                        onChangeJobType(JOB_TYPES.all);
                    }}
                >
                    View All
                </button>
                <button
                    className={
                        activeJob === JOB_TYPES.sales ? activeJobButton : otherButtons
                    }
                    onClick={() => {
                        onChangeJobType(JOB_TYPES.sales);
                    }}
                >
                    Sales
                </button>
                <button
                    className={
                        activeJob === JOB_TYPES.webDevelopment
                            ? activeJobButton
                            : otherButtons
                    }
                    onClick={() => {
                        onChangeJobType(JOB_TYPES.webDevelopment);
                    }}
                >
                    Web Development
                </button>
                <button
                    className={
                        activeJob === JOB_TYPES.digitalMarketing
                            ? activeJobButton
                            : otherButtons
                    }
                    onClick={() => {
                        onChangeJobType(JOB_TYPES.digitalMarketing);
                    }}
                >
                    Digital Marketing
                </button>
                <button
                    className={
                        activeJob === JOB_TYPES.hr ? activeJobButton : otherButtons
                    }
                    onClick={() => {
                        onChangeJobType(JOB_TYPES.hr);
                    }}
                >
                    Hr
                </button>
                <button
                    className={
                        activeJob === JOB_TYPES.accounts ? activeJobButton : otherButtons
                    }
                    onClick={() => {
                        onChangeJobType(JOB_TYPES.accounts);
                    }}
                >
                    Accounts
                </button>
                <button
                    className={
                        activeJob === JOB_TYPES.legal ? activeJobButton : otherButtons
                    }
                    onClick={() => {
                        onChangeJobType(JOB_TYPES.legal);
                    }}
                >
                    Legal
                </button>
            </div>

            <div className="avl-jobs-container">
                {filteredJobs.map((each) => (
                    <div
                        key={each.id}
                        style={{
                            boxShadow: "2px 2px 9px rgba(0, 0, 0, 0.25)",
                            background: "#FFFFFF",
                        }}
                        className="job-container"
                    >
                        <h6 className="job-main-heading job-card-heading">
                            {each.job_group}
                        </h6>
                        <div className="">
                            <div>
                                <h1 className="detailed-heading">
                                    {each.title}
                                </h1>
                                <p className="job-description">
                                    {each.description}

                                </p>
                            </div>
                        </div>
                        <div className="location-job-type-con">
                            <div >
                                <GoLocation />
                                <span>{each.location}</span>
                            </div>
                            <div className='employment-type'>
                                <BiTimeFive />
                                <span>{each.employment_type}</span>
                            </div>
                        </div>
                        <div className='edit-delete-con'>
                            <a href={`/career/view-job/${each.id}`} data-title='view' className='edit-delete-icon'>
                                <GrFormView />
                            </a>
                            <a href={`/career/edit-job/${each.id}`} data-title='edit' className='edit-delete-icon'>
                                <BiEdit />
                            </a>
                            <button onClick={() => {
                                deleteJobApplication(each.id)
                            }} data-title='delete' className='edit-delete-icon'>
                                <AiOutlineDelete />
                            </button>


                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Career