import React, { Component, useState } from 'react'
import NavBar from '../Layout/Nav'
import './index.css'
import './editJob.css'
import { useContext } from "react"
import { AppContext } from "../../context/AppContext";

import { GrFormAdd } from 'react-icons/gr'
import { AiOutlineDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
// import  { useState } from 'react';
import Popup from 'reactjs-popup';
import { toast } from 'react-toastify'



class ViewJob extends Component {
    state = {
        jobId: "",
        jobGroup: "",
        title: "",
        description: "",
        location: "",
        employmentType: "",
        jobType: "",
        detailedTitle: "",
        vision: "",
        about: "",
        detailedLocation: "",
        detailedEmploymentType: "",
        responsibilities: [],
        qualifications: [],
        tempQualification: "",
        tempResponsibility: "",
        isLoading: true
    }

    componentDidMount() {
        const { jobId } = this.props.match.params
        this.fetchData(jobId)
    }

    fetchData = async (jobId) => {
        const response = await fetch(`${process.env.REACT_APP_APIURL}/career/${jobId}`, {
            method: "GET"
        })

        if (response.ok) {
            const data = await response.json()
            const job = data.jobDetails
            this.setState({
                jobId: job.id,
                jobGroup: job.job_group,
                title: job.title,
                description: job.description,
                location: job.location,
                employmentType: job.employment_type,
                jobType: job.job_type,
                detailedTitle: job.detailed_title,
                vision: job.vision,
                about: job.about,
                detailedLocation: job.detailed_location,
                detailedEmploymentType: job.detailed_employment_type,
                responsibilities: job.responsibilities,
                qualifications: job.qualifications,
                isLoading: false
            })
            console.log(data)
        } else {
            this.setState({ isLoading: false })
        }
    }

    deleteQualificationOrResponsibility = async (id, attr) => {

        const url = attr === "qualification" ? `${process.env.REACT_APP_APIURL}/career/delete-qualification/${id}` : `${process.env.REACT_APP_APIURL}/career/delete-responsibility/${id}`
        const response = await fetch(url, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${this.context.user.token}`
            }
        })

        if (response.ok) {
            toast.success('deleted !!', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            this.fetchData(this.state.jobId)
        } else {
            toast.error('unable to delete try again...!!', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    }


    deleteJobApplication = async (id) => {
        const response = await fetch(`${process.env.REACT_APP_APIURL}/career/${id}`, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${this.context.user.token}`
            }
        })
        if (response.ok) {
            toast.success('deleted!!', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            this.props.history.replace("/career")

        } else {
            toast.error('unable to delete!!', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }

    }
    render() {
        const { jobId, jobType, detailedTitle, vision, about, detailedLocation, detailedEmploymentType, jobGroup, title, description, location, employmentType, responsibilities, qualifications, tempQualification, tempResponsibility, createJob } = this.state
        var detailed_employment_type1 = detailedEmploymentType.split(",")
        return (
            <div className='mt-5  pt-3'>
                <NavBar />
                <div>
                    <div className="pl-3 pr-3 mb-4 text-left">

                        <div className="tw-p-5">
                            <h4 >{jobGroup}</h4>
                            <h1 className='detailed-heading' >{detailedTitle}</h1>
                            <a href={`/career/edit-job/${jobId}`} style={{
                                position: "absolute",
                                right: "48px",
                                top: "76px",
                                fontSize: "40px"
                            }}>
                                <BiEdit />
                            </a>
                            <button onClick={() => {
                                this.deleteJobApplication(jobId)
                            }} style={{
                                position: "absolute",
                                right: "85px",
                                top: "76px",
                                fontSize: "40px",
                                background: "transparent",
                                border: "none"
                            }}>
                                <AiOutlineDelete />
                            </button>
                            <div >
                                <h6 className="job-main-heading mb-2 job-card-heading">
                                    Job Description
                                </h6>
                                <p className="pl-1">{description}</p>
                            </div>

                            <div >
                                <h6 className="job-main-heading mb-2 job-card-heading">
                                    Company's vision
                                </h6>
                                <p className="pl-1">{vision}</p>
                            </div>
                            <div>
                                <h6 className="job-main-heading mb-2 job-card-heading">
                                    About Nishkaera
                                </h6>
                                <p className="pl-1">{about}</p>
                            </div>
                            <div>
                                <div className='d-flex align-items-center'>
                                    <h6 className="job-main-heading mb-2 job-card-heading">
                                        Qualifications
                                    </h6>
                                    <AddQualificationOrResponsibility fetchData={this.fetchData} attribute="qualification" functionality="create" jobId={jobId} attributeId="" />
                                </div>

                                <ul>
                                    {qualifications.map((each) => (
                                        <li key={each.id}>
                                            <span >{each.qualification}</span>
                                            <AddQualificationOrResponsibility fetchData={this.fetchData} attribute="qualification" functionality="edit" jobId={jobId} value={each.qualification} attributeId={each.id} />
                                            <button onClick={() => {
                                                this.deleteQualificationOrResponsibility(each.id, "qualification")
                                            }} className='edit-delete-icon ml-3 '>
                                                <AiOutlineDelete />
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div>
                                <div >
                                    <div className='d-flex align-items-center'>
                                        <h6 className="job-main-heading mb-2 job-card-heading">
                                            Job Responsibilities
                                        </h6>
                                        <AddQualificationOrResponsibility fetchData={this.fetchData} attribute="responsibility" functionality="create" jobId={jobId} value="" attributeId="" />

                                    </div>

                                    <ul>
                                        {responsibilities.map((each) => (
                                            <li key={each.id}>
                                                {each.responsibility}
                                                <AddQualificationOrResponsibility fetchData={this.fetchData} attribute="responsibility" functionality="edit" jobId={jobId} value={each.responsibility} attributeId={each.id} />

                                                <button onClick={() => {
                                                    this.deleteQualificationOrResponsibility(each.id, "responsibility")
                                                }} className='edit-delete-icon ml-3' ><AiOutlineDelete /></button>

                                            </li>
                                        ))}

                                    </ul>
                                </div>
                                <div>
                                    <h6 className="job-main-heading mb-2 job-card-heading">Employment Type</h6>
                                    <ul>
                                        {detailed_employment_type1.map((each) => (
                                            <li key={each}>{each}</li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="tw-mt-3">
                                    <h6 className="job-main-heading mb-2 job-card-heading">Work location</h6>
                                    <span>{detailedLocation}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ViewJob

ViewJob.contextType = AppContext

const AddQualificationOrResponsibility = ({ attribute, functionality, jobId, value, attributeId, fetchData }) => {
    const { user } = useContext(AppContext)
    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);
    const [inputValue, setInputValue] = useState(value)


    const createNewAttribute = async () => {
        const response = await fetch(`${process.env.REACT_APP_APIURL}/career/add-${attribute}`, {
            method: "POST",
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify({
                job_id: jobId,
                [attribute]: inputValue
            })
        })
        const data = await response.json()
        if (response.ok) {
            fetchData(jobId)
            closeModal()
        } else {
            alert("please try again")
        }
    }

    const updateAttribute = async () => {
        const response = await fetch(`${process.env.REACT_APP_APIURL}/career/update-${attribute}`, {
            method: "PUT",
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify({
                id: attributeId,
                [attribute]: inputValue
            })
        })

        if (response.ok) {
            fetchData(jobId)
        } else {
            alert("please try again")
        }
        closeModal()
    }

    const onSubmitForm = (e) => {
        e.preventDefault()
        if (functionality === "create") {
            createNewAttribute()
        } else {
            updateAttribute()
        }
    }
    return (
        <>
            <button type="button" className="d-inline button edit-delete-icon " style={{
                marginBottom: "12px"
            }} onClick={() => setOpen(o => !o)}>
                {
                    functionality === "create" ? <GrFormAdd /> : <BiEdit />
                }
            </button>
            <Popup open={open} closeOnDocumentClick onClose={closeModal}>
                <div className="my-form bg-info text-black p-4 " style={{
                    width: "90vw",
                    maxWidth: "500px",
                    margin: "auto"
                }}>
                    <button className="close edit-delete-icon" onClick={closeModal}>
                        &times;
                    </button>
                    <form style={{
                        width: '100%'
                    }} onSubmit={onSubmitForm}>
                        <h1 >
                            {
                                attribute === "qualification" ? "Qualification" : "Responsibility"
                            }
                        </h1>
                        <textarea type='text' value={inputValue} className="form-text-area w-100" placeholder=' enter value' onChange={(e) => {
                            setInputValue(e.target.value)
                        }} />

                        <button type='submit ' className='job-btn mt-4 text-center '>
                            {
                                functionality === "create" ? "add" : "update"
                            }
                        </button>
                    </form>

                </div>
            </Popup>
        </>
    );
};