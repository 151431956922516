
import Table from "react-bootstrap/Table"
import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';

import EditRow from "./EditRow"


const privateBondDetails = [
  {
    id: 1,
    coupon: "11.55%",
    security: "Clix Capital Services Pvt Ltd 2023 (Unsecured)",
    maturity: "25-May-23",
    interestPayout: "31st of every Month",
    rating: "A by CARE",
    minimumInvestment: "Multiples of 10 Lacs"
  },
  {
    id: 2,
    coupon: "11.55%",
    security: "Clix Capital Services Pvt Ltd 2023 (Unsecured)",
    maturity: "25-May-23",
    interestPayout: "31st of every Month",
    rating: "A by CARE",
    minimumInvestment: "Multiples of 10 Lacs"
  },
  {
    id: 3,
    coupon: "11.00%",
    security: "Kogta Financial (India) Ltd 2023 (Secured) (FV 43,322)",
    maturity: "Partial Redemption of 3.3340% each month starting from 10-Apr 2021 till 10-Sep-2023",
    interestPayout: "10th of every Month",
    rating: "A by CARE",
    minimumInvestment: "Multiples of 4.3322 Lacs"
  },
  {
    id: 4,
    coupon: "11.00%",
    security: "Kogta Financial (India) Ltd 2023  (Secured) (FV 43,322)",
    maturity: "Partial Redemption of 3.3340% each month starting from 10-Apr 2021 till 10-Sep-2023",
    interestPayout: "10th of every Month",
    rating: "A by CARE",
    minimumInvestment: "Multiples of 1 cr"
  },
  {
    id: 5,
    coupon: "11.00%",
    security: "Kogta Financial (India) Ltd 2023 (Secured) (FV 43,322)",
    maturity: "Partial Redemption of 3.3340% each month starting from 10-Apr 2021 till 10-Sep-2023",
    interestPayout: "10th of every Month",
    rating: "A by CARE",
    minimumInvestment: "Multiple of 5 cr"
  },
  {
    id: 6,
    coupon: "11.00%",
    security: "Lendingkart Finance",
    maturity: "29-Aug-24",
    interestPayout: "",
    rating: "BBB+",
    minimumInvestment: "Multiple of 10 Lacs"
  },
  {
    id: 7,
    coupon: "10.85%",
    security: "Shriram Housing Finance Ltd. 2023 (Unsecured)",
    maturity: "09-May-23",
    interestPayout: "9th of Every Month",
    rating: "AA by CRISIL & AA by CARE",
    minimumInvestment: "Multiples of 10 Lacs"
  },
  {
    id: 8,
    coupon: "10.75%",
    security: "MAS Financial Services Ltd. 2027 (Sub-Debt)",
    maturity: "20-May-27",
    interestPayout: "20th of Every Month",
    Rrating: "A+ by CARE",
    minimumInvestment: "Multiples of 1 cr"
  },
  {
    id: 9,
    coupon: "10.75%",
    security: "Spandana Sphoorty",
    maturity: "16-May-23",
    interestPayout: "",
    rating: "A",
    minimumInvestment: "Multiple of 10 Lacs"
  },
]


const PrivateBond = ({ bondType }) => {
  const [tableData, setTableData] = useState([])



  const [editField, setEditField] = useState({
    coupon: 0.00,
    security: "",
    maturity: "",
    interestPayout: "",
    rating: "",
    minInvestment: "",
    bondType: ""
  })
  const [showEditRow, setshowEditRow] = useState(false)

  const [addNewRow, setAddNewRow] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`${process.env.REACT_APIURL}/bond/privateBond`)
      if (response.ok) {
        const data = await response.json()
        setTableData(data)
      }

    }
    fetchData()
  }, [showEditRow])

  return (
    <>


      <div className="mt-5 p-3 pt-5" >
        <h1 className="text-center" >Private Bonds </h1>

        <>
          <div className='d-flex mb-2 justify-content-between align-items-center'>
            <Button className='' variant="primary" onClick={async () => {
              await setEditField({
                "coupon": 0.00, "security": "", "maturity": "", "interestPayout": "", "rating": "", "minInvestment": "", "bondType": ""
              });
              await setAddNewRow(true);
              setshowEditRow(true);
            }}>
              Add new
            </Button>
          </div>
          <Table striped bordered hover>

            <thead>
              <tr>
                <td>
                  Coupon
                </td>
                <td>
                  Security
                </td>

                <td>
                  Maturity
                </td>
                <td>
                  Interest Payout
                </td>
                <td>
                  Rating
                </td>
                <td>Minimum Investment</td>

              </tr>
            </thead>
            <tbody>
              {tableData.map((each, index) => (
                <tr key={each.id} onClick={async (e) => {
                  await setEditField(tableData[index]);
                  setAddNewRow(false);
                  setshowEditRow(true);
                }}>
                  <td>{each.coupon}</td>
                  <td>{each.security}</td>
                  <td>{each.maturity}</td>
                  <td>{each.interestPayout}</td>
                  <td>{each.rating}</td>
                  <td>{each.minInvestment}</td>

                </tr>
              ))}

            </tbody>
          </Table></>


        {showEditRow && <EditRow addNewRow={addNewRow} data={editField} showModal={showEditRow} bondType={bondType} closeModal={setshowEditRow} />}
      </div>
    </>

  )
}

export default PrivateBond;


