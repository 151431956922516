import { Component } from "react"
import Spinner from 'react-bootstrap/Spinner';
import Table from "react-bootstrap/Table"
import { BsFillXSquareFill } from 'react-icons/bs'
import { TiTick } from 'react-icons/ti'
import NavBar from "../../Layout/Nav";

class HealthInsurance extends Component {
    state = {
        usersData: [{
            buyPolicyFor: "Individual",
            sumAssured: "eee",
            primaryInsuredGender: "Male",
            primaryInsuredAge: "34",
            proposerPincode: "517152",
            additionalFamilyMemAdults: "3",
            additionalFamilyMemChildren: "3",
            proposerMobileNo: "7032171466",
            adult1: "Male",
            adult2: "Male",
            adult3: "Female",
            adultSec1: "21",
            adultSec2: "30",
            adultSec3: "22",
            child1: "Female",
            child2: "Male",
            child3: "Male",
            childSec1: "11",
            childSec2: "09",
            childSec3: "08",
    }],
        isLoading: true
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = async () => {
        // const response = await fetch(`${"https://dev.contechit.com:3000"}/insurance/bike/applications`)

        // if (response.ok) {
        //     const data = await response.json()
        //     console.log(data)
        //     this.setState({ usersData: data.result, isLoading: false })
        // }
        this.setState({isLoading: false })
    }


    updateStatusToSent = async (id, index) => {
        // need to develop the api till
        // const response = await fetch(`${"https://dev.contechit.com:3000"}/insurance/bike/applications`) 
        let newArray = this.state.usersData

        newArray[index].application_sent = '1'
        this.setState({ usersData: newArray })

    }

    render() {
        const { isLoading, usersData } = this.state
        return (
            <>
                <NavBar />
                <div className="pt-2 mt-5">

                    <h1 className="mt-5">Health Insurance</h1>
                    {
                        isLoading ?
                            <div className="h-100 w-100 d-flex justify-content-center align-items-center">
                                <Spinner animation="border" variant='success' />
                            </div>
                            :
                            <div className="p-3">
                                <Table striped bordered hover responsive>

                                    <thead variant="dark" style={{ backgroundColor: "black", color: 'white' }}>
                                        <tr>
                                            <td>Buy Policy For</td>
                                            <td>Sum Assured</td>
                                            <td>Primary Insured Gender</td>
                                            <td>Primary Insured Age</td>
                                            <td>Proposer Pincode</td>
                                            <td>Family Member Adults</td>
                                            <td>Family Member Children</td>
                                            <td>Proposer Mobile No</td>
                                            <td>adult1</td>
                                            <td>adult2</td>
                                            <td>adult3</td>
                                            <td>adult Age1</td>
                                            <td>adult Age2</td>
                                            <td>adult Age3</td>
                                            <td> child1</td>
                                            <td> child2</td>
                                            <td> child3</td>
                                            <td> child Age1</td>
                                            <td> child Age2</td>
                                            <td> child Age3</td>
                                            {/* <td>Applied Date</td> */}
                                            <td>Application Sent</td>
                                            <td ></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {usersData.map((each, index) => (
                                            <tr key={each.application_id} >
                                                <td >{each.buyPolicyFor}</td>
                                                <td>{each.sumAssured}</td>
                                                <td>{each.primaryInsuredGender}</td>
                                                <td>{each.primaryInsuredAge}</td>
                                                <td>{each.proposerPincode}</td>
                                                <td>{each.additionalFamilyMemAdults}</td>
                                                <td>{each.additionalFamilyMemChildren}</td>
                                                <td>{each.proposerMobileNo}</td>
                                                <td>{each.adult1}</td>
                                                <td>{each.adult2}</td>
                                                <td>{each.adult3}</td>
                                                <td>{each.adultSec1}</td>
                                                <td>{each.adultSec2}</td>
                                                <td>{each.adultSec3}</td>
                                                <td>{each.child1}</td>
                                                <td>{each.child2}</td>
                                                <td>{each.child3}</td>
                                                <td>{each.childSec1}</td>
                                                <td>{each.childSec2}</td>
                                                <td>{each.childSec3}</td>
                                                {/* <td>{new Date(each.user_applied_date).toLocaleDateString()}</td> */}
                                                <td>{each.application_sent !== '0' ? <span className="text-success">Sent <TiTick /></span> : <span className="text-danger">Not Sent <BsFillXSquareFill /></span>}</td>
                                                <td>
                                                    <button onClick={() => {
                                                        this.updateStatusToSent(each.application_id, index)
                                                    }} className="btn btn-primary" disabled={each.application_sent !== '0'}>
                                                        {each.application_sent === '0' ? 'Apply' : "Applied"}
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </Table>
                            </div>
                    }
                </div>
            </>

        )
    }
}

export default HealthInsurance