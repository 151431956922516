import Table from "react-bootstrap/Table"
import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';

import Example from "./EditRow"


const BajajTable = ({ depositType }) => {

  const [non_senior_big, setNonSeniorBig] = useState([])
  const [senior_big, setSeniorBig] = useState([])
  const [non_senior_small, setNonSeniorSmall] = useState([])
  const [senior_small, setSeniorSmall] = useState([])
  const [allTables, setAllTables] = useState([])

  const [editField, setEditField] = useState({
    tenor: "15 ",
    cumulative: "6.40",
    monthly: "6.22",
    quarterly: "6.25",
    halfyearly: "6.30",
    annual: "6.40"
  })
  const [showEditRow, setshowEditRow] = useState(false)

  const [addNewRow, setAddNewRow] = useState(false)


  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`${process.env.REACT_APP_APIURL}/rates/Bajaj/?table_type=senior_small&deposit_type=${depositType}`)
      if (response.ok) {
        const data = await response.json()
        setSeniorSmall(data)
      }

    }
    fetchData()
  }, [depositType, showEditRow])
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`${process.env.REACT_APP_APIURL}/rates/Bajaj/?table_type=non_senior_small&deposit_type=${depositType}`)
      if (response.ok) {
        const data = await response.json()
        setNonSeniorSmall(data)
      }

    }
    fetchData()
  }, [depositType, showEditRow])
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`${process.env.REACT_APP_APIURL}/rates/Bajaj/?table_type=senior_big&deposit_type=${depositType}`)
      if (response.ok) {
        const data = await response.json()
        setSeniorBig(data)
      }

    }
    fetchData()
  }, [depositType, showEditRow])

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`${process.env.REACT_APP_APIURL}/rates/Bajaj/?table_type=non_senior_big&deposit_type=${depositType}`)
      if (response.ok) {
        const data = await response.json()
        setNonSeniorBig(data)
      }
    }
    fetchData()
  }, [depositType, showEditRow])

  useEffect(() => {
    setAllTables([non_senior_small, senior_small, non_senior_big, senior_big])
  }, [senior_big, non_senior_big, senior_small, non_senior_small])

  return (
    <>

      <div className="" >
        <h1 className="text-center text-primary" >{depositType} Rates </h1>
        {
          allTables.map((eachTable, tableIndex) => (
            <>
              {eachTable.length !== 0 &&
                <div className='d-flex mb-2 justify-content-between align-items-center'>
                  <h1 className='text-center'>{eachTable[0].table_type}</h1>
                  {console.log(eachTable)}
                  <Button className=' ' variant="primary" onClick={async () => {
                    await setEditField({
                      "tenor": "", "cumulative": "", "monthly": "", "quarterly": "", "halfyearly": "", "annual": "", deposit_type:eachTable[0].deposit_type , table_type: eachTable[0].table_type
                    });
                    await setAddNewRow(true);
                    setshowEditRow(true);
                  }}>
                    Add new
                  </Button>
                </div>}
              <Table striped bordered hover>

                <thead>
                  <tr>
                    <td>
                      tenor
                    </td>
                    <td>
                      Cumulative
                    </td>
                    <td>
                      monthly
                    </td>
                    <td>
                      quarterly
                    </td>
                    <td>
                      halfyearly
                    </td>
                    <td>
                      annual
                    </td>

                  </tr>
                </thead>
                <tbody>
                  {eachTable.map((each, index) => (
                    <tr key={each.id} onClick={async (e) => {
                      await setEditField(eachTable[index]);
                      setAddNewRow(false);
                      setshowEditRow(true);
                    }}>
                      <td>{each.tenor}</td>
                      <td>{each.cumulative}</td>
                      <td>{each.monthly}</td>
                      <td>{each.quarterly}</td>
                      <td>{each.halfyearly}</td>
                      <td>{each.annual}</td>

                    </tr>
                  ))}

                </tbody>
              </Table></>
          ))
        }
        {/* <div className='d-flex mb-2 justify-content-between align-items-center' >
          <h1 className='text-center'>Bajaj Normal Citizen Big</h1>
          <Button className=' ' variant="primary" onClick={async () => {
            await setEditField({
              "tenor": "", "cumulative": "", "monthly": "", "quarterly": "", "halfyearly": "", "annual": "", depositType: depositType, table_type: ""
            })
            await setAddNewRow(true)
            await setUpdateTable({ tableName: "bajaj_normalcitizen_big", type: "big" })
            setshowEditRow(true)
          }}>
            Add new
          </Button>
        </div>

        <Table striped bordered hover>
          <thead >
            <tr >
              <td>
                tenor
              </td>
              <td>
                Cumulative
              </td>
              <td>
                monthly
              </td>
              <td>
                quarterly
              </td>
              <td>
                halfyearly
              </td>
              <td>
                annual
              </td>

            </tr>
          </thead>
          <tbody >
            {
              non_senior_big.map((each, index) => (
                <tr key={each.id} onClick={async (e) => {
                  await setEditField(non_senior_big[index])
                  await setUpdateTable({ tableName: "bajaj_normalcitizen_big", type: "big" })
                  setAddNewRow(false)
                  setshowEditRow(true)
                }}>
                  <td >{each.tenor}</td>
                  <td >{each.cumulative}</td>
                  <td >{each.monthly}</td>
                  <td >{each.quarterly}</td>
                  <td >{each.halfyearly}</td>
                  <td >{each.annual}</td>

                </tr>
              ))
            }
          </tbody>
        </Table>
        <div className='d-flex mb-2 justify-content-between align-items-center' >
          <h1 className='text-center'>Bajaj Senior Citizen Big</h1>
          <Button className=' ' variant="primary" onClick={async () => {
            await setEditField({
              "tenor": "", "cumulative": "", "monthly": "", "quarterly": "", "halfyearly": "", "annual": ""
            })
            await setAddNewRow(true)
            await setUpdateTable({ tableName: "bajaj_seniorcitizen_big", type: "big" })
            setshowEditRow(true)
          }}>
            Add new
          </Button>
        </div>
        <Table striped bordered hover>
          <thead >
            <tr >
              <td>
                tenor
              </td>
              <td>
                Cumulative
              </td>
              <td>
                monthly
              </td>
              <td>
                quarterly
              </td>
              <td>
                halfyearly
              </td>
              <td>
                annual
              </td>

            </tr>
          </thead>
          <tbody >
            {
              senior_big.map((each, index) => (
                <tr key={each.id} onClick={async (e) => {
                  await setEditField(senior_big[index])
                  await setUpdateTable({ tableName: "bajaj_seniorcitizen_big", type: "big" })
                  await setAddNewRow(false)
                  setshowEditRow(true)
                }}>
                  <td >{each.tenor}</td>
                  <td >{each.cumulative}</td>
                  <td >{each.monthly}</td>
                  <td >{each.quarterly}</td>
                  <td >{each.halfyearly}</td>
                  <td >{each.annual}</td>

                </tr>
              ))
            }
          </tbody>
        </Table>
        <div className='d-flex mb-2 justify-content-between align-items-center' >
          <h1 className='text-center'>Bajaj Normal Citizen small</h1>
          <Button className=' ' variant="primary" onClick={async () => {
            await setEditField({
              "min_tenor": "", "max_tenor": "", "cumulative": "", "monthly": "", "quarterly": "", "halfyearly": "", "annual": ""
            })
            await setAddNewRow(true)
            await setUpdateTable({ tableName: "bajaj_normalcitizen_small", type: "small" })
            setshowEditRow(true)
          }}>
            Add new
          </Button>
        </div>
        <Table striped bordered hover>
          <thead >
            <tr >
              <td >tenor</td>
              <td>
                Cumulative
              </td>
              <td>
                monthly
              </td>
              <td>
                quarterly
              </td>
              <td>
                halfyearly
              </td>
              <td>
                annual
              </td>

            </tr>
          </thead>
          <tbody >
            {
              non_senior_small.map((each, index) => (
                <tr key={each.id} onClick={async (e) => {
                  await setEditField(non_senior_small[index])
                  await setUpdateTable({ tableName: "bajaj_normalcitizen_small", type: "small" })
                  await setAddNewRow(false)
                  setshowEditRow(true)
                }}>
                  <td >{each.tenor}</td>
                  <td >{each.cumulative}</td>
                  <td >{each.monthly}</td>
                  <td >{each.quarterly}</td>
                  <td >{each.halfyearly}</td>
                  <td >{each.annual}</td>

                </tr>
              ))
            }
          </tbody>
        </Table>
        <div className='d-flex mb-2 justify-content-between align-items-center' >
          <h1 className='text-center'>Bajaj Senior Citizen small</h1>
          <Button className=' ' variant="primary" onClick={async () => {
            await setEditField({
              "min_tenor": "", "max_tenor": "", "cumulative": "", "monthly": "", "quarterly": "", "halfyearly": "", "annual": ""
            })
            await setAddNewRow(true)
            await setUpdateTable({ tableName: "bajaj_seniorcitizen_small", type: "small" })
            setshowEditRow(true)
          }}>
            Add new
          </Button>
        </div>
        <Table striped bordered hover>
          <thead >
            <tr >
              <td >tenor</td>
              <td>
                Cumulative
              </td>
              <td>
                monthly
              </td>
              <td>
                quarterly
              </td>
              <td>
                halfyearly
              </td>
              <td>
                annual
              </td>

            </tr>
          </thead>
          <tbody >
            {
              senior_small.map((each, index) => (
                <tr key={each.id} onClick={async (e) => {
                  await setEditField(senior_small[index])
                  await setUpdateTable({ tableName: "bajaj_seniorcitizen_small", type: "small" })
                  await setAddNewRow(false)
                  setshowEditRow(true)
                }}>
                  <td >{each.tenor}</td>
                  <td >{each.cumulative}</td>
                  <td >{each.monthly}</td>
                  <td >{each.quarterly}</td>
                  <td >{each.halfyearly}</td>
                  <td >{each.annual}</td>

                </tr>
              ))
            }
          </tbody>
        </Table> */}
        {showEditRow && <Example addNewRow={addNewRow} data={editField} showModal={showEditRow} depositType={depositType} tableName={editField.table_type}  closeModal={setshowEditRow} />}
      </div>
    </>

  )
}

export default BajajTable