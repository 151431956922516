import { Component } from "react"
import Spinner from 'react-bootstrap/Spinner';
import Table from "react-bootstrap/Table"
import { BsFillXSquareFill } from 'react-icons/bs'
import { TiTick } from 'react-icons/ti'
import NavBar from "../../Layout/Nav";
import { AppContext } from "../../../context/AppContext";

class CarInsurance extends Component {
    state = {
        usersData: [],
        isLoading: true
    }
    static contextType = AppContext;

    componentDidMount() {
        this.fetchData()
    }

    fetchData = async () => {
        const da = this.context.user
        console.log(da)
        const response = await fetch(`${"https://dev.contechit.com:3000"}/insurance/life/users`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${this.context.user.token}`
            }
        })

        if (response.ok) {
            const data = await response.json()
            console.log(data)
            this.setState({ usersData: data.results, isLoading: false })
        } else {
            const data = await response.json()
            console.log(response, data)
        }
    }


    updateStatusToSent = async (id, index) => {
        // need to develop the api till
        // const response = await fetch(`${"https://dev.contechit.com:3000"}/insurance/car/applications`) 
        let newArray = this.state.usersData

        newArray[index].application_sent = '1'
        this.setState({ usersData: newArray })

    }

    render() {
        const { isLoading, usersData } = this.state
        return (
            <>
                <NavBar />
                <div className="pt-4 mt-5">

                    <h1 >Life Insurance</h1>
                    {
                        isLoading ?
                            <div className="h-100 w-100 d-flex justify-content-center align-items-center">
                                <Spinner animation="border" variant='success' />
                            </div>
                            :
                            <div className="p-3">
                                <Table striped bordered hover responsive>

                                    <thead variant="dark" style={{ backgroundColor: "black", color: 'white' }}>
                                        <tr>
                                        <td>Gender</td>
                                            <td>Date Of Birth</td>
                                            <td>Policy Term</td>
                                            <td>Tobaco Habit</td>
                                            <td>Sum Assured</td>
                                             <td>Primary Insured Pincode</td>
                                            <td>Mobile</td>
                                            <td>Email</td>
                                            <td>Annual Income</td>
                                            <td>Education</td>
                                            <td>Occupation</td>
                                            <td>Application Sent</td>
                                            <td >Status</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {usersData.map((each, index) => (
                                            <tr key={each.uid} >
                                                <td>{each.gender}</td>
                                                <td >{new Date(each.dob).toLocaleDateString()}</td>
                                                <td>{each.policyTerm}</td>
                                                <td>{each.tobaco}</td>
                                                <td>{each.sumAssured}</td>
                                                <td>{each.pincode}</td>
                                                <td>{each.mobile}</td>
                                                <td>{each.email}</td>
                                                <td>{each.annualIncome}</td>
                                                <td>{each.education}</td>
                                                <td>{each.occupation}</td>
                                                <td>{each.application_sent !== '0' ? <span className="text-success">Sent <TiTick /></span> : <span className="text-danger">Not Sent <BsFillXSquareFill /></span>}</td>
                                                <td>
                                                    <button onClick={() => {
                                                        this.updateStatusToSent(each.application_id, index)
                                                    }} className="btn btn-primary" disabled={each.application_sent !== '0'}>
                                                        {each.contactStatus === 0 ? 'Apply' : "Applied"}
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </Table>
                            </div>
                    }
                </div>
            </>

        )
    }
}
// const ExportToExcel = ({ apiData, fileName }) => {
//     const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
//     const fileExtension = ".xlsx";
//     const exportToCSV = (apiData, fileName) => {
//       const ws = XLSX.utils.json_to_sheet(apiData);
//       const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
//       const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
//       const data = new Blob([excelBuffer], { type: fileType });
//       FileSaver.saveAs(data, fileName + fileExtension);
//     };
//     return (
//       <Button onClick={(e) => exportToCSV(apiData, fileName)}>Export</Button>
//     );
//   }
  

export default CarInsurance



