import React from 'react';
import "./webinar.css"
 const Pagination =({registerPerPage,TotalRegister,paginate})=>{
    const pageNumbers=[]
      for(let i=1; i<= Math.ceil(TotalRegister / registerPerPage); i++){
          pageNumbers.push(i);
      }
      

    return(
        <div className="pagination">
            
               {pageNumbers.map(number=>(
                   <li  className='page-item ' key={number}
                   onClick={()=>paginate(number)}
                   >
                       <a href="#!"  className="page-link" > {number}</a>
                       
                          
                    

                   </li>
               ))} 
            

        </div>
    )
}
export default Pagination;