
import { useState } from "react"
import NavBar from "../../Layout/Nav"
import BajajTable from "./Rates"

const BajajRates = () => {
    const [depositType, setDepositType] = useState("Bajaj_fd")
    return (
        <>
            <NavBar />
            <div className="mt-5 p-5" >

                <div className="d-flex flex-row align-item-end justify-content-center ">
                    <button onClick={() => {
                        setDepositType("Bajaj_fd")
                    }} style={{ outline: "none", boxShadow: "none" }} className={`rounded-0 btn btn-primary bg-transparent border-4 border-top-0 border-right-0 border-left-0  pr-3 pl-3 ${depositType === "Bajaj_fd" ? "border-primary text-primary font-weight-bold h1" : "border-secondary h6 text-secondary"}`} >Bajaj Fd</button>
                    <button onClick={() => {
                        setDepositType("SDP")
                    }} style={{ outline: "none", boxShadow: "none" }} className={`rounded-0 btn btn-primary bg-transparent border-4 border-top-0 border-right-0 border-left-0  pr-3 pl-3 ${depositType === "SDP" ? "border-primary text-primary font-weight-bold h1" : "border-secondary h6 text-secondary"}`}>SDP</button>
                    <button onClick={() => {
                        setDepositType("NRI_fd")
                    }} style={{ outline: "none", boxShadow: "none" }} className={`rounded-0 btn btn-primary bg-transparent border-4 border-top-0 border-right-0 border-left-0  pr-3 pl-3 ${depositType === "NRI_fd" ? "border-primary text-primary font-weight-bold h1" : "border-secondary h6 text-secondary"}`} >NRI Fd</button>
                </div>
                <BajajTable depositType={depositType} />
            </div>
        </>

    )

}

export default BajajRates 