import Table from "react-bootstrap/Table"
import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';

import NcdModal from "./EditRow"
import NavBar from "../../Layout/Nav"


const NcdTable = ({ depositType }) => {

    const [table, setTableData] = useState([])

    const [editField, setEditField] = useState({
        "id": "",
        "issuer": "",
        "rating": "",
        "coupon": "",
        "maturityDate": ""
    })
    const [showEditRow, setshowEditRow] = useState(false)

    const [addNewRow, setAddNewRow] = useState(false)
    const fetchData = async () => {
        const response = await fetch(`${process.env.REACT_APP_APIURL}/ncd/`)
        const data = await response.json();
        console.log(data)
        setTableData(data.results)
    }
    useEffect(() => {

        fetchData()
    }, [])


    return (
        <>
            <NavBar />

            <div className="mt-5 p-3 pt-5" >

                <div className="d-flex justify-content-between align-items-center"  >
                    <h1 className='text-center'>NCD </h1>
                    <Button className=' ' variant="primary" onClick={async () => {
                        await setEditField({
                            issuer: "", rating: "", coupon: "", maturityDate: ""
                        });
                        await setAddNewRow(true);
                        setshowEditRow(true);
                    }}>
                        Add new
                    </Button>

                </div>

                <Table striped bordered hover>

                    <thead>
                        <tr>
                            <td>
                                ncdID
                            </td>
                            <td>
                                issuer
                            </td>

                            <td>
                                rating
                            </td>
                            <td>
                                coupon
                            </td>
                            <td>
                                maturityDate
                            </td>

                        </tr>
                    </thead>
                    <tbody>
                        {table.map((each, index) => (
                            <tr key={each.id} onClick={async (e) => {
                                await setEditField(each);
                                setAddNewRow(false);
                                setshowEditRow(true);

                            }}>
                                <td>{each.ncdID}</td>
                                <td>{each.issuer}</td>
                                <td>{each.rating}</td>
                                <td>{each.coupon}</td>
                                <td>{each.maturityDate}</td>

                            </tr>
                        ))}

                    </tbody>
                </Table>


                {showEditRow && <NcdModal addNewRow={addNewRow} data={editField} showModal={showEditRow} closeModal={setshowEditRow} fetchData={fetchData} />}
            </div>
        </>

    )
}

export default NcdTable