import axios from 'axios'
import React, { useState, useEffect, useContext } from 'react'
import "./User.css"
import dotenv from "dotenv"
import { AppContext } from '../../context/AppContext'
import { Container, Table } from 'react-bootstrap'
import Pagination from "../Webinar/Pagination"
import NavBar from "../Layout/Nav";
import CircularLoader from '../circularloader/CircularLoader'
dotenv.config()
export default function Users() {
    const { user } = useContext(AppContext)
    const [users, setUsers] = useState([])
    const [currentpage, setCurrentPage] = useState(1);
    const [registerPerPage, setRegisterPerPage] = useState(5);
    const [progress, setProgress] = useState(true)


    // const [currentregisters,setCurrentRegister]=useState([])
    const indexOfLastRegister = currentpage * registerPerPage;
    const indexOfFirstRegister = indexOfLastRegister - registerPerPage;
    const currentRegisters = users.slice(indexOfFirstRegister, indexOfLastRegister)
    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber)

    }



    const fetchUsers = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_APIURL}/api/`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
            console.log(res, res.data)
            setUsers(res.data.results)
            setProgress(false)
        } catch (error) {
            console.log(error)
        }
    }
    const handleslectchange = (e) => {
        setCurrentPage(1)

        setRegisterPerPage(e.target.value)
    }
    useEffect(() => {
        fetchUsers()
    }, [])

    return (
        <>
            {progress && <CircularLoader />}

            <NavBar />

            <Container>





                <div style={{ display: "flex", width: "90%", justifyContent: "flex-end", marginTop: "100px" }}>

                    <select className="mr-4" name="items" onChange={handleslectchange}  >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value={users.length}>{users.length}</option>
                    </select>



                </div>
                <Table style={{ margin: "20px 0px" }}>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Pnone no </th>
                            <th>Verified</th>
                            <th>Token</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentRegisters.map((user, i) =>
                                <tr>
                                    <td >{indexOfFirstRegister + i + 1}</td>
                                    <td>{user.name}</td>
                                    <td>{user.email}</td>
                                    <td>{user.phone}</td>
                                    <td>{user.isVerify ? "yes" : "no"}</td>
                                    <td>{user.token}</td>
                                </tr>

                            )
                        }
                    </tbody>



                </Table>

                <Pagination registerPerPage={registerPerPage} TotalRegister={users.length} paginate={paginate} />
            </Container>
        </>
    )

}
