import React, { Component } from 'react'

import { AiOutlineDelete } from "react-icons/ai";
import { BiEdit } from 'react-icons/bi';

import { toast } from "react-toastify";

import { AppContext } from "../../context/AppContext";
import './editJob.css'
import './index.css'

class EditJob extends Component {

    state = {

        jobGroup: "",
        title: "",
        description: "",
        location: "",
        employmentType: "",
        jobType: "",
        detailedTitle: "",
        vision: "",
        about: "",
        detailedLocation: "",
        detailedEmploymentType: "",
        responsibilities: [],
        qualifications: [],
        tempQualification: "",
        tempResponsibility: "",
        jobId: "",
        // "jobType": "kjfsdlg",
        // "detailedTitle": "fsdfs",
        // "vision": "dsfsdgf",
        // "about": "aflsdougfdskl",
        // "detailedLocation": "lkhfildskjf",
        // "detailedEmploymentType": "fgsjdfhsd",
        // "jobGroup": "sf;kjsdg",
        // "title": "f's;lgsd",
        // "location": "dsfklshgs",
        // "employmentType": "sdf;jls;kg",
        // "responsibilities": [
        //     "John",
        //     "Peter",
        //     "Sally",
        //     "Jane"
        // ],
        // "qualifications": [
        //     "John",
        //     "Peter",
        //     "Sally",
        //     "Jane"
        // ],

        createJob: true
    }

    componentDidMount() {
        const path = this.props.match.path
        if (path !== '/career/create-job') {
            this.setState({ createJob: false })
            const { jobId } = this.props.match.params
            this.fetchData(jobId)
        }
    }

    fetchData = async (jobId) => {
        const response = await fetch(`${process.env.REACT_APP_APIURL}/career/${jobId}`, {
            method: "GET"
        })


        if (response.ok) {
            const data = await response.json()
            const job = data.jobDetails
            this.setState({
                jobId: job.id,
                jobGroup: job.job_group,
                title: job.title,
                description: job.description,
                location: job.location,
                employmentType: job.employment_type,
                jobType: job.job_type,
                detailedTitle: job.detailed_title,
                vision: job.vision,
                about: job.about,
                detailedLocation: job.detailed_location,
                detailedEmploymentType: job.detailed_employment_type,
                responsibilities: job.responsibilities,
                qualifications: job.qualifications,
                isLoading: false
            })
            console.log(data)
        } else if (response.status === 405) {
            alert("position not available")
            this.props.history.replace('/career')
        } else {
            this.setState({ isLoading: false })
        }
    }
    onChangeInput = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }


    addQualificationToList = (e) => {
        if (e.key === "Enter" && this.state.tempQualification !== "") {
            e.preventDefault()
            this.setState((prevState) => ({
                qualifications: [...prevState.qualifications, prevState.tempQualification],
                tempQualification: ""
            }))
        }
    }

    addResponsibilityToList = (e) => {
        if (e.key === "Enter" && this.state.tempResponsibility !== "") {
            e.preventDefault()

            this.setState((prevState) => ({
                responsibilities: [...prevState.responsibilities, prevState.tempResponsibility],
                tempResponsibility: ""
            }))
        }
    }

    removeQualification = (index) => {
        const { qualifications } = this.state
        let temp = qualifications
        temp.splice(index, 1)
        this.setState(({ qualifications: temp }))
    }

    removeResponsibility = (index) => {
        const { responsibilities } = this.state
        let temp = responsibilities
        temp.splice(index, 1)
        this.setState(({ responsibilities: temp }))
    }

    editResponsibility = (index) => {
        const { responsibilities } = this.state
        let temp = responsibilities
        let removed = temp.splice(index, 1)
        this.setState(({ responsibilities: temp, tempResponsibility: removed }))
    }

    editQualification = (index) => {
        const { qualifications } = this.state
        let temp = qualifications
        let removed = temp.splice(index, 1)
        this.setState(({ qualifications: temp, tempQualification: removed }))
    }

    newJobApplication = async () => {
        const { user } = this.context
        const { jobType, description, detailedTitle, vision, about, detailedLocation, detailedEmploymentType, jobGroup, title, location, employmentType, responsibilities, qualifications } = this.state
        const response = await fetch(`${process.env.REACT_APP_APIURL}/career/new-job`, {
            method: "POST",
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify({ jobType, description, detailedTitle, vision, about, detailedLocation, detailedEmploymentType, jobGroup, title, location, employmentType, responsibilities, qualifications })
        })

        if (response.ok) {
            toast.success('New Position created!!', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            this.props.history.goBack()

        } else {
            toast.success('Something went wrong ...!!', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    }

    updateJobApplication = async () => {
        const { user } = this.context
        const { jobId, jobType, description, detailedTitle, vision, about, detailedLocation, detailedEmploymentType, jobGroup, title, location, employmentType, responsibilities, qualifications } = this.state
        const response = await fetch(`${process.env.REACT_APP_APIURL}/career/edit-job`, {
            method: "PUT",
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify({ jobId, jobType, description, detailedTitle, vision, about, detailedLocation, detailedEmploymentType, jobGroup, title, location, employmentType, responsibilities, qualifications })
        })


        if (response.ok) {
            toast.success('Position updated!!', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            this.props.history.goBack()

        } else {
            toast.success('Something went wrong ...!!', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    }

    createNewJobPosition = (e) => {
        e.preventDefault()

        if (this.state.createJob) {
            this.newJobApplication()
        } else {
            this.updateJobApplication()
        }
    }


    renderQualificationsAndResponsibilities = () => {
        const { responsibilities, qualifications, tempQualification, tempResponsibility } = this.state

        return (
            <>
                <div className='input-label-con'>
                    <label className='form-label'>Qualifications:</label>
                    <ul >
                        {
                            qualifications.map((each, index) => (
                                <li key={index}>
                                    {each}
                                    <button type='button' className='edit-delete-icon' onClick={() => {
                                        this.editQualification(index)
                                    }}>
                                        <BiEdit />
                                    </button>
                                    <button type='button' className='edit-delete-icon' onClick={() => {
                                        this.removeQualification(index)
                                    }}>
                                        <AiOutlineDelete />
                                    </button>
                                </li>
                            ))
                        }
                    </ul>
                    <input type='text' className='form-input' onChange={this.onChangeInput} onKeyDown={this.addQualificationToList} placeholder='e.g Financial Analyst Intern' value={tempQualification} name='tempQualification' />
                </div>
                <div className='input-label-con'>
                    <label className='form-label'>Responsibilities:</label>
                    <ul >
                        {
                            responsibilities.map((each, index) => (
                                <li key={index}>
                                    {each}
                                    <button type='button' className='edit-delete-icon' onClick={() => {
                                        this.editResponsibility(index)
                                    }}>
                                        <BiEdit />
                                    </button>
                                    <button type='button' className='edit-delete-icon' onClick={() => {
                                        this.removeResponsibility(index)
                                    }}>
                                        <AiOutlineDelete />
                                    </button>
                                </li>
                            ))
                        }
                    </ul>
                    <input type='text' className='form-input' onChange={this.onChangeInput} onKeyDown={this.addResponsibilityToList} placeholder='e.g Financial Analyst Intern' value={tempResponsibility} name='tempResponsibility' />
                </div>

            </>
        )
    }

    render() {

        const { jobType, detailedTitle, vision, about, detailedLocation, detailedEmploymentType, jobGroup, title, description, location, employmentType, responsibilities, qualifications, tempQualification, tempResponsibility, createJob } = this.state
        return (
            <div className='job-form-container'>
                <form className='my-form' onSubmit={this.createNewJobPosition}>
                    <h1 className='job-main-heading'>Create New Job</h1>
                    <div className='input-label-con'>
                        <label className='form-label'>Job Group :</label>
                        <select className='form-input' required onChange={this.onChangeInput} value={jobGroup} name="jobGroup">
                            <option >select</option>
                            <option value="Sales and Marketing">Sales and Marketing</option>
                            <option value="HR">HR</option>
                            <option value="Web Development">Web Development</option>
                            <option value="Accounts">Accounts</option>
                            <option value="Legal">Legal</option>

                        </select>
                    </div>
                    <div className='input-label-con'>
                        <label className='form-label'>title:</label>
                        <input required type='text' className='form-input' onChange={this.onChangeInput} placeholder='e.g Sales Manager (Fixed income)' value={title} name='title' />
                    </div>
                    <div className='input-label-con'>
                        <label className='form-label'>description:</label>
                        <input required type='text' className='form-input' onChange={this.onChangeInput} value={description} placeholder='e.g We are looking for highly motivate...' name='description' />
                    </div>
                    <div className='input-label-con'>
                        <label className='form-label'>location:</label>
                        <input required type='text' className='form-input' onChange={this.onChangeInput} value={location} placeholder='e.g Guargon' name='location' />
                    </div>
                    <div className='input-label-con'>
                        <label className='form-label'>Employment Type:</label>
                        <input required type='text' className='form-input' onChange={this.onChangeInput} value={employmentType} placeholder='e.g part time ,full time etc' name='employmentType' />
                    </div>

                    <div className='input-label-con'>
                        <label className='form-label'>Job Type:</label>
                        <input required type='text' className='form-input' onChange={this.onChangeInput} value={jobType} placeholder='e.g sales' name='jobType' />
                    </div>
                    <div className='input-label-con'>
                        <label className='form-label'>Detailed Title:</label>
                        <input required type='text' className='form-input' onChange={this.onChangeInput} placeholder='e.g Financial Analyst Intern' value={detailedTitle} name='detailedTitle' />
                    </div>

                    <div className='input-label-con'>
                        <label className='form-label'>Company's Vision :</label>
                        <textarea required type='text' className='form-input form-text-area' onChange={this.onChangeInput} placeholder='e.g We foresee a future...' value={vision} name='vision' />
                    </div>

                    <div className='input-label-con'>
                        <label className='form-label'>About company :</label>
                        <textarea required type='text' className='form-input form-text-area' onChange={this.onChangeInput} placeholder='e.g Nishkaera is a financ...' value={about} name='about' />
                    </div>


                    {createJob && this.renderQualificationsAndResponsibilities()}

                    <div className='input-label-con'>
                        <label className='form-label'>Detailed Job Location:</label>
                        <input required type='text' className='form-input' onChange={this.onChangeInput} value={detailedLocation} placeholder='e.g delhi' name='detailedLocation' />
                    </div>

                    <div className='input-label-con'>
                        <label className='form-label'>Detailed Employment Type:</label>
                        <input required type='text' className='form-input' onChange={this.onChangeInput} value={detailedEmploymentType} placeholder='e.g part time, full time etc' name='detailedEmploymentType' />
                    </div>

                    <button type='submit' className='job-btn mt-4 mb-5' >Submit</button>

                </form>
            </div>
        )
    }

}


EditJob.contextType = AppContext
export default EditJob
