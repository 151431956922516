

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React, { useState, useEffect } from 'react';

const EditRow = ({ data, showModal, closeModal, bondType, tableName, addNewRow }) => {
  const [editField, setEditField] = useState({})
  const handleClose = () => closeModal(false);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setEditField(data)
  }, [])

  const submitData = async () => {
    setLoading(true)
    const methodType=addNewRow?"create":"update"
    const res = await fetch(`${process.env.REACT_APP_APIURL}/bond/${methodType}`, {
      method: addNewRow ? "POST" : "PUT",
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify(editField)
    })
    setLoading(false)
    if (res.ok) {
      closeModal(false)
    } else {
      alert("unable to save please try again")
    }

  }

  const deleteRow = async () => {
    setLoading(true)
    const res = await fetch(`${process.env.REACT_APP_APIURL}/bond/?id=${editField.id}`, {
      method: "DELETE",
    })

    if (res.ok) {
      console.log("deleted")
      closeModal(false)
    } else {
      alert("Please try again")
    }
    setLoading(false)
  }

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{addNewRow ? "adding new row " : "Editing row "}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

       

          <div class="form-floating mb-2">
            <input type="decimal" onChange={(e) => {
              setEditField((prev) => ({ ...prev, coupon: e.target.value }))
            }} value={editField.coupon} class="form-control" id="coupon" />
            <label for="coupon">Coupon</label>
          </div>

          <div class="form-floating mb-2">
            <input type="text" onChange={(e) => {
              setEditField((prev) => ({ ...prev, maturity: e.target.value }))
            }} value={editField.maturity} class="form-control" id="maturity" />
            <label for="maturity ">Maturity</label>
          </div>
          <div class="form-floating mb-2">
            <input type="text" onChange={(e) => {
              setEditField((prev) => ({ ...prev, interestPayout: e.target.value }))
            }} value={editField.interestPayout} class="form-control" id="interestPayout" />
            <label for="interestPayout">Interest Payout</label>
          </div>
          <div class="form-floating mb-2">
            <input type="text" onChange={(e) => {
              setEditField((prev) => ({ ...prev, rating: e.target.value }))
            }} value={editField.rating} class="form-control" id="rating" />
            <label for="rating ">Rating</label>
          </div>
          <div class="form-floating mb-2">
            <input type="text" onChange={(e) => {
              setEditField((prev) => ({ ...prev, quantum: e.target.value }))
            }} value={editField.quantum} class="form-control" id="quantum" />
            <label for="quantum">Quantum</label>
          </div>
       
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={loading} variant={loading ? "success" : "primary"} onClick={submitData}>
            {loading ? "processing request..." : "save"}
          </Button>
          {
            !addNewRow && <Button disabled={loading} variant="danger" onClick={deleteRow}>
              {loading ? "processing request..." : "delete"}
            </Button>
          }

        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EditRow

