
import React from 'react'
import { Navbar, Container, Nav, Button } from "react-bootstrap";
import { NavLink, useHistory } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';

import "./nav.css"
// import { GiHamburgerMenu } from "react-icons/gi";
// import { MdClose } from "react-icons/md";

export default function NavBar() {
  const history = useHistory()
  const handlelogout = () => {
    localStorage.removeItem("user")
    history.push("/login")


  }
  return (
    <Navbar bg="light" variant="light" className='shadow' style={{ height: "", marginBottom: "100px" }} fixed="top">
      <Container>
        <Navbar.Brand href="/">
          <img src="/NishkaEra_Logo.jpg" alt="Nishkaera" height="30" />
        </Navbar.Brand>
        <Nav className="me-end" style={{ alignItems: "center" }}>
          <NavLink activeStyle={{ color: "rgb(58 125 109)" }} style={{ textDecoration: "none", marginRight: "15px", color: "#3ad4af" }} exact to="/"><b >IPO Watch</b></NavLink>
          <NavLink activeStyle={{ color: "rgb(58 125 109)" }} style={{ textDecoration: "none", marginRight: "15px", color: "#3ad4af" }} exact to="/Latest"><b >Latest</b></NavLink>
          <NavLink activeStyle={{ color: "rgb(58 125 109)" }} style={{ textDecoration: "none", marginRight: "15px", color: "#3ad4af" }} exact to="/Marque"><b >Banner</b></NavLink>
          <NavLink activeStyle={{ color: "rgb(58 125 109)" }} style={{ textDecoration: "none", marginRight: "15px", color: "#3ad4af" }} exact to="/Advisor"><b >Advisor</b></NavLink>
          <NavLink activeStyle={{ color: "rgb(58 125 109)" }} style={{ textDecoration: "none", marginRight: "15px", color: "#3ad4af" }} exact to="/Webinar"><b >Webinar</b></NavLink>
          <NavLink activeStyle={{ color: "rgb(58 125 109)" }} style={{ textDecoration: "none", marginRight: "15px", color: "#3ad4af" }} exact to="/users"><b >Users</b></NavLink>

          <Dropdown className="mr-2">
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              Fixed Deposits
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="/bajaj">Bajaj</Dropdown.Item>
              <Dropdown.Item href="/shriram">Shriram </Dropdown.Item>

            </Dropdown.Menu>
          </Dropdown>
          <Dropdown className="mr-2">
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              Insurance
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="/insurance/car">Car Insurance</Dropdown.Item>
              <Dropdown.Item href="/insurance/bike">Two Wheeler Insurance </Dropdown.Item>
              <Dropdown.Item href="/insurance/life">Life Insurance</Dropdown.Item>
              <Dropdown.Item href="/insurance/health">Health Insurance </Dropdown.Item>

            </Dropdown.Menu>
          </Dropdown>
          <Dropdown className="mr-2">
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              products
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="/products/ncd">NCD</Dropdown.Item>
              <Dropdown.Item href="/products/bonds">Bonds</Dropdown.Item>
              <Dropdown.Item href="/products/p2p">P2P</Dropdown.Item>
              <Dropdown.Item href="/products/mutual-funds">Mutual Funds</Dropdown.Item>
              <Dropdown.Item href="/products/wealth-management">wealth management</Dropdown.Item>
              <Dropdown.Item href="/products/car-loan">car loan</Dropdown.Item>

            </Dropdown.Menu>
          </Dropdown>
          <Dropdown className="mr-2">
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              Others
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="/career">career</Dropdown.Item>
              <Dropdown.Item href="/partners">Partners </Dropdown.Item>

            </Dropdown.Menu>
          </Dropdown>
          {/* <Nav.Link active href="/IPOWatch"><b style={{color:"#3ad4af"}}>IPO Watch</b></Nav.Link>
            <Nav.Link href="/Latest"><b style={{color:"#3ad4af"}}>Latest</b></Nav.Link>            
            <Nav.Link href="/Marque"><b style={{color:"#3ad4af"}}>Marque</b></Nav.Link> */}

          <Button onClick={handlelogout}>logout</Button>

        </Nav>
      </Container>
    </Navbar>
  )
}


