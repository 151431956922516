import { Component } from "react";

import { Table } from "react-bootstrap";
import NavBar from "../Layout/Nav";

import { AppContext } from "../../context/AppContext";

class DigitalMarketing extends Component {
	state = {
		userDetails: [
			{
				id: 1,
				name: "vinay",
				contact_no: "8688986889",
				email: "test1@gmail.com",
				city: "test city 1",
			},
			{
				id: 2,
				name: "lucky",
				contact_no: "1234567890",
				email: "lucky23@gmail.com",
				city: "test city 2",
			},
			{
				id: 3,
				name: "rani",
				contact_no: "8734687634",
				email: "rani123@gmail.com",
				city: "test city 3",
			},
		],
	};

	static contextType = AppContext;

	componentDidMount() {
		this.fetchDetails();
	}

	fetchDetails = async () => {
		const response = await fetch(
			`${process.env.REACT_APP_APIURL}/digital-marketing`,
			{
				method: "GET",
				headers: {
					Authorization: `Bearer ${this.context.user.token}`,
					// Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7InVzZXJfaWQiOiJjOGFhMTZhZS0xNmM2LTRiMDItYjZiNS03Mjc3ZWJhMWU2MzgiLCJlbWFpbCI6Im1hbmdhbGFwZW50YXZpbmF5a3VtYXJyZWRkeTMwQGdtYWlsLmNvbSIsInJvbGUiOiJhZG1pbiJ9LCJpYXQiOjE3MDcyMjEyODUsImV4cCI6MTgwNzIyNDg4NX0.997FNBFhAC-f40afDENhb7BZZGgOTH0j6dZgfVPzHzo`,
				},
			}
		);

		// console.log(this.context.user.token);
		if (response.ok) {
			const data = await response.json();
			this.setState({ userDetails: data });
		} else {
			alert("Internal Server Error");
		}
	};

	render() {
		const { userDetails } = this.state;
		return (
			<>
				<NavBar />
				<div className="mt-5 pt-2 p-3">
					<h1 className="mt-5">Digital Marketing Details</h1>
					<Table striped bordered hover>
						<thead>
							<tr>
								<td>
									<b>Name</b>
								</td>
								<td>
									<b>Contact No</b>
								</td>
								<td>
									<b>Email</b>
								</td>
								<td>
									<b>City</b>
								</td>
							</tr>
						</thead>
						<tbody className="">
							{userDetails.map((user) => (
								<tr id={user.id}>
									<td>{user.name}</td>
									<td>{user.contact_no}</td>
									<td>{user.email}</td>
									<td>{user.city}</td>
								</tr>
							))}
						</tbody>
					</Table>
				</div>
			</>
		);
	}
}

export default DigitalMarketing;
