import { Component } from "react"
import Spinner from 'react-bootstrap/Spinner';
import Table from "react-bootstrap/Table"
import { BsFillXSquareFill } from 'react-icons/bs'
import { TiTick } from 'react-icons/ti'
import NavBar from "../Layout/Nav";
import { AppContext } from "../../context/AppContext";
import { Modal } from "react-bootstrap";

class Partners extends Component {
    state = {
        usersData: [],
        isLoading: true,
        showModal: false,
        user: {}
    }
    static contextType = AppContext;

    componentDidMount() {
        this.fetchData()
    }

    fetchData = async () => {
        const da = this.context.user
        console.log(da)
        const response = await fetch(`${process.env.REACT_APP_APIURL}/partner`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${this.context.user.token}`
            }
        })

        if (response.ok) {
            const data = await response.json()
            console.log(data)
            this.setState({ usersData: data.results, isLoading: false })
        } else {
            const data = await response.json()
            console.log(response, data)
            alert("please try logging in again and check it ")
        }
    }


    updateStatusToSent = async (id) => {
        const response = await fetch(`${process.env.REACT_APP_APIURL}/partner`, {
            method: "PATCH",
            headers: {
                'Authorization': `Bearer ${this.context.user.token}`
            }
        })

        if (response.ok) {
            this.fetchData()
        } else {
            alert('Unable to update...!!  please try again')
        }
    }

    viewDetailedView = (user) => {
        this.setState({ user, showModal: true })
        // this.props.history.push("/products/ncd/user", { user: user })
    }



    render() {
        const { isLoading, usersData, user } = this.state
        return (
            <>
                <NavBar />
                <div className="pt-4 mt-5">

                    <h1 >Partners</h1>
                    {
                        isLoading ?
                            <div className="h-100 w-100 d-flex justify-content-center align-items-center">
                                <Spinner animation="border" variant='success' />
                            </div>
                            :
                            <div className="p-3 text-center d-flex flex-row justify-content-center align-items-center">
                                <Table size="lg" className="rounded w-100" striped bordered hover responsive>

                                    <thead variant="dark" style={{ backgroundColor: "black", color: 'white' }}>
                                        <tr>
                                            <td>First Name</td>
                                            <td>Last Name</td>
                                            <td>Age </td>
                                            <td>Education  </td>
                                            <td>City</td>
                                            <td>Email</td>
                                            <td >Mobile</td>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {usersData.map((each) => (
                                            <tr key={each.id} >
                                                <td className="text-left">{each.first_name}</td>
                                                <td>{each.last_name}</td>
                                                <td>{each.age}</td>
                                                <td>{each.education}</td>
                                                <td>{each.city}</td>
                                                <td>{each.email}</td>
                                                <td>{each.mobile}</td>


                                                {/* <td>{each.contactStatus !== 0 ? <span className="text-success">Sent <TiTick /></span> : <span className="text-danger">Not Sent <BsFillXSquareFill /></span>}</td> */}
                                                {/* <td>
                                                    <button onClick={() => {
                                                        this.viewDetailedView(each)
                                                    }} className={`btn text-decoration-underline text-primary`} >
                                                        view
                                                    </button>
                                                </td> 
                                                 <td>
                                                    <button onClick={() => {
                                                        this.updateStatusToSent(each.uid)
                                                    }} className={`btn ${each.contactStatus === 0 ? "btn-primary" : "btn-success"}`} disabled={each.contactStatus !== 0}>
                                                        {each.contactStatus === 0 ? 'Contact' : "Contacted"}
                                                    </button>
                                                </td> */}
                                            </tr>
                                        ))}

                                    </tbody>
                                </Table>
                                {/* <div className="text-left border m-2 w-50 p-2">
                                    <h3 className="text-decoration-underline"> User Details</h3>

                                    <div >
                                        <b >Name:    </b><span >{user.name}</span>
                                    </div>
                                    <div >
                                        <b >Mobile:    </b><span >{user.mobile}</span>
                                    </div>
                                    <div >
                                        <b >Email:    </b><span >{user.email}</span>
                                    </div>
                                    <div >
                                        <b >Location:    </b><span >{user.location}</span>
                                    </div>
                                    <div >
                                        <b >Employment Type:    </b><span >{user.employementType}</span>
                                    </div>
                                    <div >
                                        <b >Annual Income:    </b><span >{user.annualIncome}</span>
                                    </div>
                                    {user.contactStatus === 0 ? <button onClick={() => {
                                        this.updateStatusToSent(user.uid)
                                    }} className={`btn mt-4 ${user.contactStatus === 0 ? "btn-primary" : "btn-success"}`} disabled={user.contactStatus !== 0}>
                                        {user.contactStatus === 0 ? 'Contact' : "Contacted"}
                                    </button> :
                                        <div >
                                            <b >Status:    </b><span >Contacted</span>
                                        </div>

                                    }
                                </div> */}

                            </div>
                    }
                </div>

            </>

        )
    }
}

export default Partners