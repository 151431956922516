import {
  AppBar,
  Box,
  Input,
  makeStyles,
  Tab,
  Tabs,
  Typography,

} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Jumbotron, Row, Table, Modal, Button, Form } from "react-bootstrap";
import PropTypes from "prop-types";
import NavBar from "../Layout/Nav";
import "./webinar.css";
import WebinarCardUpcoming from "./WebinarUpcoming";
import WebinarAdd from "./WebinarAdd";
import axios from "axios";
import * as FileSaver from 'file-saver';
import * as XLSX from "xlsx";
import Pagination from "./Pagination";
import { FcCheckmark } from "react-icons/fc";



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const Webinar = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [upcoming, setUpcoming] = React.useState([])
  const [registers, setregisters] = React.useState([])

  const [webinars, setWebinars] = React.useState([])
  const [detailmodalshow, setDetailmodalshow] = React.useState(false)
  const [registeruser, setRegisteruser] = React.useState({})
  const [webinar, setWebinar] = React.useState({})

  const findwebinar = (id) => {
    setWebinar(webinars.find(web => id === web.webId))

  }


  const [currentpage, setCurrentPage] = useState(1);
  const [registerPerPage, setRegisterPerPage] = useState(5);


  // const [currentregisters,setCurrentRegister]=useState([])
  const indexOfLastRegister = currentpage * registerPerPage;
  const indexOfFirstRegister = indexOfLastRegister - registerPerPage;
  const currentRegisters = registers.slice(indexOfFirstRegister, indexOfLastRegister)
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber)

  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const upcoming = [
  //   {
  //     webId: "398b68d1-4ef7-49b8-860c-11a923f368b9",
  //     name: "Nishkaera Financial Literacy",
  //     speaker: "Ms Rushika Sabnis",
  //     descp: "Webinar on Basics of Personal Finance",
  //     date: "3rd October 2021",
  //     time: "11:00 am - 12:00 pm",
  //     status: "upcoming",
  //     detail:
  //       "1. Basics of Financial Planning – By Ms Rushika Sabnis, SEBI registered Investment Advisor \n• What is financial planning?\n• Why one need financial planning?\n• Key components of financial planning\n• Savings – How to save more?\n• Managing Expenses / Debts – What are the options available?  \n• Budgeting – How to do it?\n• Investing – How to start and where to invest\n• Benefits of doing financial planning and goal setting at an early age\n2. Financial Goals: How to set them, manage them and achieve them – By Mr. Sanjeev Dawar, Personal Finance Expert\n• What are financial goals?\n• Why setting financial goals is important?\n• How to set your financial goals?\n• What role financial goals play in your financial journey?\n• How to manage and revise your financial goals depending upon your changing financial status?",
  //     spkr_url: "NA",
  //     spkr_org: "SEBI",
  //     spkr_desig: "SEBI registered Investment Advisor",
  //     spkr_descp: "NA",
  //     timestamp: "CURRENT_TIMESTAMP ON UPDATE CURRENT_TIMESTAMP",
  //   },
  // ];

  const fetchwebinars = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_APIURL}/api/webinar/`,

      )
      let result = res.data.results
      setWebinars(res.data.results)
      let upcoming = result.filter(item => item.status === "upcoming")
      setUpcoming(upcoming)


    } catch (error) {
      console.log(error.response)
    }
  }
  const fetchregister = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_APIURL}/api/webinar/register`)

      setregisters(res.data.results)
    } catch (error) {
      console.log(error.response)
    }

  }
  const handleslectchange = (e) => {
    setCurrentPage(1)

    setRegisterPerPage(e.target.value)
  }



  const sendreminder1 = async (id, email) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_APIURL}/api/webinar/reminder1`, {
        id,
        reminder1: true,
        email
      })

      fetchregister()

    } catch (error) {

    }

  }
  const sendreminder2 = async (id, email) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_APIURL}/api/webinar/reminder2`, {
        id,
        reminder2: true,
        email
      })

      fetchregister()

    } catch (error) {


    }

  }
  const sendregisterconfirm = async (id, email) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_APIURL}/api/webinar/register/confirm`, {
        id,
        email
      })
      fetchregister()

    } catch (error) {
      console.log(error)

    }

  }
  const sendendmsg = async (id, email) => {
    try {

      const res = await axios.post(`${process.env.REACT_APP_APIURL}/api/webinar/register/sendendmsg`, {
        id,
        email

      })
      fetchregister()

    } catch (error) {
      console.log(error)

    }

  }
  const resendendmsg = async (id) => {
    try {

      const res = await axios.post(`${process.env.REACT_APP_APIURL}/api/webinar/register/resendendmsg`, {
        id

      })
      fetchregister()

    } catch (error) {
      console.log(error)

    }

  }
  const resendregisterconfirm = async (id) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_APIURL}/api/webinar/register/resendconfirm`, {
        id
      })

      fetchregister()

    } catch (error) {
      console.log(error)


    }

  }
  const resendreminder1 = async (id) => {
    try {
      const res = await axios.patch(`${process.env.REACT_APP_APIURL}/api/webinar/reminder1/${id}`)

      fetchregister()

    } catch (error) {
      console.log(error)


    }

  }
  const resendreminder2 = async (id) => {
    try {
      const res = await axios.patch(`${process.env.REACT_APP_APIURL}/api/webinar/reminder2/${id}`)
      fetchregister()

    } catch (error) {
      console.log(error)


    }

  }
  const sendweblink = async (id, email) => {
    try {

      const res = await axios.post(`${process.env.REACT_APP_APIURL}/api/webinar/register/webinarlink`, {
        id,
        email

      })
      fetchregister()

    } catch (error) {
      console.log(error)

    }

  }
  const resendweblink = async (id) => {
    try {
      const res = await axios.patch(`${process.env.REACT_APP_APIURL}/api/webinar/resendwebinarlink`)
      fetchregister()

    } catch (error) {
      console.log(error)


    }

  }
  const sendreminder1Toall = () => {
    const arr = registers.filter(data => data.reminder1 === 0)
    for (var i = 0; i < arr.length; i++) {
      sendreminder1(arr[i].id, arr[i].email)
    }
  }
  // const sendconfirmationToall=()=>{
  //   const arr = registers.filter(data=>data.register===0)
  //   for(var i=0;i<arr.length;i++){
  //     sendregisterconfirm(arr[i].id,arr[i].email)
  //   }
  //  }  
  const sendreminder2Toall = () => {
    const arr = registers.filter(data => data.reminder2 === 0)
    for (var i = 0; i < arr.length; i++) {
      sendreminder2(arr[i].id, arr[i].email)

    }
  }
  const sendwebinarlinktoall=()=>{
    const arr = registers.filter(data => data.webinarlink === 0)
    for (var i = 0; i < arr.length; i++) {
      sendweblink(arr[i].id, arr[i].email)

    }
  }
  const sendendmsgtoall=()=>{
    const arr = registers.filter(data => data.end_msg === 0)
    for (var i = 0; i < arr.length; i++) {
      sendendmsg(arr[i].id, arr[i].email)

    }
  }
 
  useEffect(() => {
    fetchwebinars()
    fetchregister()
  }, [])
  return (
    <>
      <NavBar />
      <Jumbotron className="container-fluid">
        <h1 class="webinar-header">WEBINARS</h1>
        <div className="webinar-container">
          <div className={classes.root}>
            <AppBar position="static" color="default" className="webinar-tab">
              <Tabs value={value} onChange={handleChange}>
                <Tab label="Upcoming" />
                <Tab label="Recent" />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              <Row>
                <WebinarAdd e={upcoming[0]} />
                {upcoming.map((e) => {
                  return <WebinarCardUpcoming e={e} />;
                })}
              </Row>
            </TabPanel>
            <TabPanel value={value} index={1}>
              {/* <WebinarCardRecent /> */}
            </TabPanel>
          </div>
        </div>


      </Jumbotron>
      <h4>Webinar Register Users</h4>

      <div style={{ display: "flex", width: "90%", justifyContent: "flex-end" }}>

        <select className="mr-4" name="items" onChange={handleslectchange}  >
          <option value="5">5</option>
          <option value="10">10</option>
          <option value={registers.length}>{registers.length}</option>
        </select>

        <ExportToExcel apiData={registers} fileName="register_user" /></div>
      <div style={{ width: "80%", margin: "20px auto" }}>

        <Table bordered responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Registeration</th>
              <th><Button onClick={sendwebinarlinktoall}>Send All</Button> webinarlink</th>
              <th><Button onClick={sendreminder1Toall}  >Send All</Button> Reminder1</th>
              <th><Button onClick={sendreminder2Toall}>Send All</Button> reminder2</th>
              <th><Button onClick={sendendmsgtoall}>Send All</Button> End msg</th>
              <th>show more</th>

            </tr>
          </thead>
          <tbody>
            {currentRegisters.map((data, i) =>

              <tr>
                <td data-chec>{indexOfFirstRegister + i + 1}</td>
                <td>{data.name}</td>
                <td>{data.email}</td>
                <td>{data.phoneno}</td>
                <td>{data.register === 0 ? <Button onClick={() => sendregisterconfirm(data.id, data.email)}>send</Button> : <div><FcCheckmark />
                  <p style={{ cursor: "pointer" }} onClick={() => resendregisterconfirm(data.id)}>Resend</p></div>}</td>
                <td>{data.webinarlink=== 0 ? <Button onClick={() => sendweblink(data.id, data.email)}>send</Button> : <div><FcCheckmark />
                  <p style={{ cursor: "pointer" }} onClick={() => resendweblink(data.id)}>Resend</p>

                </div>}</td>
                <td>{data.reminder1 === 0 ? <Button onClick={() => sendreminder1(data.id, data.email)}>send</Button> : <div><FcCheckmark />
                  <p style={{ cursor: "pointer" }} onClick={() => resendreminder1(data.id)}>Resend</p>
                </div>}</td>
                <td>{data.reminder2 === 0 ? <Button onClick={() => sendreminder2(data.id, data.email)}>send</Button> : <div><FcCheckmark />
                  <p style={{ cursor: "pointer" }} onClick={() => resendreminder2(data.id)}>Resend</p>

                </div>}</td>
                <td>{data.end_msg === 0 ? <Button onClick={() => sendendmsg(data.id, data.email)}>send</Button> : <div><FcCheckmark />
                  <p style={{ cursor: "pointer" }} onClick={() => resendendmsg(data.id)}>Resend</p>

                </div>}</td>
                <td>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <button onClick={() => { setDetailmodalshow(true); setRegisteruser(data); findwebinar(data.webId) }}>View</button>

                  </div>
                </td>

              </tr>
            )}
          </tbody>
        </Table>
        <Pagination registerPerPage={registerPerPage} TotalRegister={registers.length} paginate={paginate} />

      </div>
      <Detailmodal
        show={detailmodalshow}
        user={registeruser}
        webinar={webinar}
        onHide={() => { setDetailmodalshow(false) }}
      />
    </>



  );
};

// Table row component


function Detailmodal(props) {
  const { timestamp } = props.user
  const [registerdate, setRegisterdate] = useState("")
  const [registertime, setRegistertime] = useState("")
  const formatdate = (timestamp) => {
    const d = new Date(timestamp)

    console.log(d.getMonth())
    const day = d.getDate()
    const month = d.getMonth()
    const year = d.getFullYear()
    const date = day + "/" + month + "/" + year
    var hours = d.getHours();
    var minutes = d.getMinutes();
    console.log(hours)
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;

    setRegisterdate(date)
    setRegistertime(strTime)

  }
  useEffect(() => {

    setRegisterdate(timestamp)

    formatdate(timestamp)

  }, [timestamp])
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered

    >
      <Modal.Header closeButton>
        <h5> Registered user details</h5>
      </Modal.Header>
      <Modal.Body>

        <p><b>Name:</b> <span>{props.user.name}</span></p>
        <p><b>Email:</b> <span>{props.user.email}</span></p>
        <p><b>Phone:</b> <span>{props.user.phoneno}</span></p>
        <p><b>Age:</b> <span>{props.user.age}</span></p>
        <p><b>Gender:</b> <span>{props.user.gender}</span></p>
        <p><b>Organization:</b> <span>{props.user.org}</span> </p>
        <p><b>Designation:</b> <span>{props.user.desg}</span></p>
        <p><b>Url:</b> <span>{props.user.url}</span></p>
        <p><b>Exceptation:</b> <span>{props.user.excep}</span></p>
        <p><b>Registeration Date:</b> <span>{registerdate}</span></p>
        <p><b>Registeration time:</b> <span>{registertime}</span></p>
        <p><b>Webinar:</b> <span>{props.webinar.name}</span></p>
        <p><b>Webinar date:</b> <span>{props.webinar.date}</span></p>
        <p><b>Webinar time:</b> <span>{props.webinar.time}</span></p>

      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Go Back</Button>

      </Modal.Footer>
    </Modal>
  )
}
const ExportToExcel = ({ apiData, fileName }) => {
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  return (
    <Button onClick={(e) => exportToCSV(apiData, fileName)}>Export</Button>
  );
}

export default Webinar;
