import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import axios from "axios";
import React from "react";
import { Col, Form, Modal } from "react-bootstrap";

function SpeakerModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Speaker details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{props.e.speaker}</h4>
        <h6 className="card-subtitle mb-2 text-muted">{props.e.spkr_desig}</h6>
        <h6 className="card-subtitle mb-2 text-muted">{props.e.spkr_org}</h6>
        <br />
        <p>Description:</p>
        <p>{props.e.spkr_descp}</p>
        {props.e.spkr_url !== "NA" && (
          <a href={props.e.spkr_url} target="_blank" rel="noreferrer">
            Connect
          </a>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Ok</Button>
      </Modal.Footer>
    </Modal>
  );
}
function ResultModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <p>The webinar details has been edited successfully!</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

function DetailModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Webinar details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{props.e.name}</h4>
        <h6 className="card-subtitle mb-2 text-muted">
          By - {props.e.speaker}, {props.e.spkr_desig}, {props.e.spkr_org}
        </h6>
        <br />
        <p style={{ whiteSpace: "pre-wrap" }}>{props.e.detail}</p>
        <br />
        <h6 className="card-subtitle mb-2 text-muted">{props.e.date}</h6>
        <h6 className="card-subtitle mb-2 text-muted">{props.e.time}</h6>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default class WebinarAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      speakerModalShow: false,
      detailModalShow: false,
      resultModalShow: false,
      webId: "",
      name: "",
      speaker: "",
      descp: "",
      date: "",
      time: "",
      detail: "",
      spkr_url: "",
      spkr_org: "",
      spkr_desig: "",
      spkr_descp: "",
      // token:props.token,
      error: null,
    };
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handlechange = this.handlechange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDeleteWebinar = this.handleDeleteWebinar.bind(this);
  }
  handlechange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    // Form submit handle code goes here
  };
  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleDeleteWebinar = () => {
    // Handles delete webinar code goes here
  };

  render() {
    return (
      <>
        <Col lg={4} md={6} sm={12}>
          {/* <div
              className="card-body"
            >
              <button
                onClick={this.handleDeleteWebinar}
                className="btn btn-danger"
                style={{
                    position: "absolute",
                    top: 5,
                    right: 5,
                    fontSize: 15,
                    padding: 2,
                }}
              >
                <svg
                  class="MuiSvgIcon-root"
                  focusable="false"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  width= "1em"
                >
                  <path fill="white" d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"></path>
                </svg>
              </button>
              <h5 className="card-title">{this.state.name}</h5>
              <h6
                onClick={() => this.setState({ speakerModalShow: true })}
                className="cursor-pointer card-subtitle mb-2 text-muted"
              >
                {this.state.speaker}
              </h6>
              <p className="card-text">{this.state.descp}</p>
              <h6 className="card-subtitle mb-2 text-muted">{this.state.date}</h6>
              <h6 className="card-subtitle mb-2 text-muted">{this.state.time}</h6>
              <div className="card-buttons">
                <button
                  onClick={() => this.setState({ detailModalShow: true })}
                  className="btn btn-primary"
                >
                  Preview
                </button>
                <button
                  onClick={this.handleClickOpen}
                  className="btn btn-primary"
                >
                  Edit
                </button>
              </div>
            </div> */}
          <div className="add-webinar" onClick={this.handleClickOpen}>
            +
          </div>
          <div>
            <Dialog
              open={this.state.open}
              onClose={this.handleClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">
                Edit webinar details
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Please the form below to add a webinar. All fields in the form
                  is required to be filled. Put "NA" incase a field has no
                  inputs.
                </DialogContentText>
                <Form id="webinar-add-from" onSubmit={this.handleSubmit}>
                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>Webinar Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter webinar name"
                      name="name"
                      onChange={this.handlechange}
                      value={this.state.name}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicSpeaker">
                    <Form.Label>Webinar Speaker</Form.Label>
                    <Form.Control
                      type="text"
                      name="speaker"
                      placeholder="Enter speaker"
                      onChange={this.handlechange}
                      value={this.state.speaker}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicDescription">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="descp"
                      placeholder="Enter description"
                      onChange={this.handlechange}
                      value={this.state.descp}
                      style={{ height: "100px" }}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicDate">
                    <Form.Label>Date</Form.Label>
                    <Form.Control
                      type="text"
                      name="date"
                      placeholder="Enter date"
                      onChange={this.handlechange}
                      value={this.state.date}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicTime">
                    <Form.Label>Time</Form.Label>
                    <Form.Control
                      type="text"
                      name="time"
                      onChange={this.handlechange}
                      placeholder="Enter time"
                      value={this.state.time}
                      required
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicDetail">
                    <Form.Label>Details</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="detail"
                      onChange={this.handlechange}
                      placeholder="Enter details of the webinar"
                      value={this.state.detail}
                      required
                      style={{ height: "100px" }}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicSpeakerURL">
                    <Form.Label>Speaker URL</Form.Label>
                    <Form.Control
                      type="text"
                      name="spkr_url"
                      onChange={this.handlechange}
                      placeholder="Enter speaker's URL"
                      value={this.state.spkr_url}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicSpeakerOrg">
                    <Form.Label>Speaker Organization</Form.Label>
                    <Form.Control
                      type="text"
                      name="spkr_org"
                      onChange={this.handlechange}
                      placeholder="Enter speaker's organization"
                      value={this.state.spkr_org}
                      required
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="formBasicSpeakerDesignation"
                  >
                    <Form.Label>Speaker Designation</Form.Label>
                    <Form.Control
                      type="text"
                      name="spkr_desig"
                      onChange={this.handlechange}
                      placeholder="Enter speaker's designation"
                      value={this.state.spkr_desig}
                      required
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="formBasicSpeakerDesignation"
                  >
                    <Form.Label>Speaker Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="spkr_descp"
                      onChange={this.handlechange}
                      placeholder="Enter speaker's description"
                      value={this.state.spkr_descp}
                      required
                      style={{ height: "100px" }}
                    />
                  </Form.Group>
                  {this.state.error && (
                    <p style={{ color: "red" }}>{this.state.error}</p>
                  )}
                </Form>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleClose} color="primary">
                  Cancel
                </Button>
                <Button type="submit" form="webinar-add-from" color="primary">
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
            {/* <SpeakerModal
              show={this.state.speakerModalShow}
              onHide={() => this.setState({ speakerModalShow: false })}
              e={this.props.e}
            />
            <DetailModal
              show={this.state.detailModalShow}
              e={this.state}
              onHide={() => this.setState({ detailModalShow: false })}
            /> */}
            <ResultModal
              show={this.state.resultModalShow}
              e={this.props.e}
              onHide={() => this.setState({ resultModalShow: false })}
            />
          </div>
        </Col>
      </>
    );
  }
}
