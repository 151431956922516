import { Component } from "react"
import NavBar from "../Layout/Nav";
// import { AppContext } from "../../../context/AppContext";

const { OAuth2Client } = require('google-auth-library');


const { GoogleSpreadsheet } = require('google-spreadsheet');

// Initialize the sheet - doc ID is the long id in the sheets URL

class Gsheets extends Component {
    state = {
        usersData: [],
        isLoading: true
    }
    // static contextType = AppContext;

    componentDidMount() {
        this.fetchData()

    }

    fetchData = async () => {
        const doc = new GoogleSpreadsheet('1ZpXhFScIWkz6rDfQCp9aIf_95yyrLZWwaFXEcl3Jsqs');


        // await doc.useServiceAccountAuth({
        //     client_email: "vinay-872@silicon-cocoa-365502.iam.gserviceaccount.com",
        //     private_key: "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCaMPT9Tnr9GUjY\n0+5Wjjw3mLVJ4dRqb0JZcOKt0kObEJu4G57lCqfukpP9eAfBld+jLm6BOBrMZw1o\nTNwaz4t7V1eNKrFh6/o1bfJy3P0pXEIAvCWPWpwcgMR+L6DF0mJwJAiOt+OIE8nQ\n5YkBl8eG2PUGA8iqeSaH6iygbUY4GG3zfRsc/X0JlS0tu/njfuVftkd8/KScFzDl\nwiPT+z9n6sbJTqrChMPeP9lOAQttn2kq4xuvmrwp7No1G1HeAErv9VnSY/BXc53s\nIcTHGhMgxP8zphrGfXbbg+/V072qcErm/SKvjBzYFTTnER5jx829Pu3SiYuJry0Z\n6Uq9EDm9AgMBAAECggEABxwZ9oJ+Js9Z6HpF5j1Vlv2xDE4ADgQBKUp3Kqsu8rE+\ncOvrH3tmjhhkVQl0wsR5pQFbgeoCSFRkeJ+kqDgo4cAqWWgeI5pZNZ9TJjXnltbk\nyeUNuVFlBeZvyQltWSR6kAUFVP6zMeoxrIgiUWMRhaJZkqmtgM4od0IWb95Mtizj\nMf1VL/GCa0PppBTDLAI+ELqNiGF6SrA2TiTzppklaHYltKKV/1NKnDbJJIlUNuYF\nDvrF/0RWiED+QPvYumF/ARfoMCmEC9wV0wsplMWMYzNZiQ+DnBb5eqsxwft5L3jI\nYN4jEFrg6gHlnUbfulevPsqtR/F7ecSbAf/s6cg8kQKBgQDO3WQZuHYlB69CZrc2\nP1V0YmVwjRLQR/j103R8y/Hf97F+m/8MBgcwl2k4vqLee+XOaz5y2LbTzx6JwcHS\nBVFT95lnTvasZQoXeM4WkEYbrHLEQi4iHlnrKkub/47Imb/7B80c4Bi3En3sFmSO\nNZLXCxtNVUkWx1XC/UYSLCIK8QKBgQC+0LM2jkwj2XYS4EkrvxgeOAi36LgGHMya\nE6h9oxhqiK4seP4n6R4dWMAMfoa2Gxv/NLRedOr4VjcOU2r5mNxBG+BL/oVT7Reg\nQ2wGfgFhmgb73BhguD3X+seioKzJ8z7/eN2H3m+crSyFEVOI9FGspn9d0qvKApdc\nGMnqI2VjjQKBgDatGYoa0U9USqR4VbCG6OVMJJetEe3vOARxkXZyyUlzRHy8apsY\n9MmJG4yeBppbkRAQFH9yfFBbjCzJA5upQCe7Z9aAbn5+bj5p9b7M/1rNJ8Br5Zmr\nAAVCNp3S3EB1MuL82t/o819gCdxextE3o8om615zTlAw+6AAJYckFxNhAoGAV+lr\nR+1TYwjjf1raG0+VWwbR7XlIn3hRQ4NfE1M7AooqRM8RuW2LtFWZVahNhEUFJxUv\nLH2NbBC8mNsR5CDrCQxA0IJZBwuq4OlvMaaIGGGQaiVDDfyUcZdWtrR0Jy7sjWMw\nHVL79SFL65rJ88Usg/DJqX5QK2f6ODIrhLX+FlkCgYEAyFDtsfY/wpCkcOWtpswE\ncEVvbolPZcM8FZu3nDSi/2+GuIsFrhYgvLd7+VHldewYrC48wkHAvFcL+SmuLL4R\nJvhe9L/yL7iNcMm0H131r6tF656lhmNK1QIF0MzsOrGtOTrbh1SHJmlrfkSnpfh2\n2p8ruxXEKZAJAO6DftGywAY=\n-----END PRIVATE KEY-----\n",
        // });
        await doc.useApiKey('AIzaSyAhACcsCwoHPSVWt_SzK99qV7mzjFA4B50');

        await doc.loadInfo(); // loads document properties and worksheets
        console.log(doc);
        // await doc.updateProperties({ title: 'renamed doc' });

        // const sheet = doc.sheetsByIndex[0]; // or use doc.sheetsById[id] or doc.sheetsByTitle[title]
        // console.log(sheet.title);
        // console.log(sheet.rowCount);

        // // adding / removing sheets
        // const newSheet = await doc.addSheet({ title: 'hot new sheet!' });
        // await newSheet.delete();
        const oauthClient = new OAuth2Client({
            clientId: '441588461460-p655tp5h187m5kqb7shc0jk74eru2d4d.apps.googleusercontent.com',
            clientSecret: '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'
          });
          
          // Pre-configure the client with credentials you have stored in e.g. your database
          // NOTE - refresh_token is required, whilt the access token and expiryDate are optional
          // (the refresh token is used to generate a missing/expired access token)
        //   const { accessToken, refreshToken, expiryDate } = await fetchUserGoogleCredsFromDatabase();
        //   oauthClient.credentials.access_token = accessToken;
        //   oauthClient.credentials.refresh_token = refreshToken;
        //   oauthClient.credentials.expiry_date = expiryDate; // Unix epoch milliseconds
          
          // Listen in whenever a new access token is obtained. You might want to store them in your database.
          // Mind that the refresh_token never changes (unless it's revoked, in which case your end-user will
          // need to go through the full authentication flow again), so storing the new access_token is optional.
          oauthClient.on('tokens', credentials => {
            console.log(credentials.access_token);
            console.log(credentials.scope);
            console.log(credentials.expiry_date);
            console.log(credentials.token_type); // will always be 'Bearer'
          })
          
        //   const doc = new GoogleSpreadsheet('<YOUR-DOC-ID>');
          doc.useOAuth2Client(oauthClient);
          

    }





    render() {
        // const { user } = this.props.location.state
        return (
            <>
                <NavBar />
                <h1 >HELLO</h1>
            </>

        )
    }
}

export default Gsheets