import React, { Component } from 'react'

import {
  Col,
  Row,
  Modal,
  Form,
} from "react-bootstrap";
import { Button } from "@material-ui/core";
import axios from 'axios';
import { AppContext } from '../../context/AppContext';


export default class EditIPO extends Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = {
      id: "",
      name: "",
      position: "",
      pdf: "",
      video: "",
      img: "",
      status: "",
      upcoming: [],
      ongoing: [],
      recent: [],
      startDate: new Date(),
      closeDate: new Date(),
      IssueSize: 0,
      IssueSizeCr: 0,
      lotSize: 0
    }
    this.handlechange = this.handlechange.bind(this)
    this.uploadImage = this.uploadImage.bind(this)
    this.convertBase64 = this.convertBase64.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  componentWillReceiveProps(props) {
    const { details } = props
    this.setState({
      // name: props.details.name,
      // pdf: props.details.pdf,
      // video: props.details.video,
      // logo: props.details.logo,
      // status: props.details.status,
      // id: props.details.id,
      // position: props.details.position,
      name: details.name,
      id: details.id,
      position: details.position,
      pdf: details.pdf,
      video: details.video,
      img: details.img,
      status: details.status,
      upcoming: details.upcoming,
      ongoing: details.ongoing,
      recent: details.recent,
      startDate: new Date(),
      closeDate: new Date(),
      IssueSize: details.issuesize,
      IssueSizeCr: details.issuesizecr,
      lotSize: details.lotsize
    })
  }


  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }


  // state = {
  //   name: "vinay",
  //   position: "",
  //   pdf: "",
  //   video: "",
  //   img: "",
  //   status: "",
  //   upcoming: [],
  //   ongoing: [],
  //   recent: [],
  //   startDate: new Date(),
  //   closeDate: new Date(),
  //   IssueSize: 0,
  //   IssueSizeCr: 0,
  //   lotSize: 0
  // }

  componentDidMount() {
    console.log(this.props)
    const { details } = this.props
    this.setState(() => ({
      name: details.name,
      position: details.position,
      pdf: details.pdf,
      id: details.id,
      video: details.video,
      img: details.img,
      status: details.status,
      upcoming: details.upcoming,
      ongoing: details.ongoing,
      recent: details.recent,
      startDate: new Date(),
      closeDate: new Date(),
      IssueSize: details.issuesize,
      IssueSizeCr: details.issuesizesr,
      lotSize: details.lotsize
    }))
  }
  handlechange = (e) => {
    if (e.target.name === "status") {
      if (e.target.value != this.props.details.status) {


        if (e.target.value === "ongoing") {
          this.setState({
            position: this.props.ongoing.length + 1
          })

        }
        if (e.target.value === "recent") {
          this.setState({
            position: this.props.recent.length + 1
          })


        }
        if (e.target.value === "upcoming") {
          this.setState({
            position: this.props.upcoming.length + 1
          })

        }
      }
    }
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleOpenChange = (e) => {
    this.setState({
      startDate: e.target.value
    })
  }
  handleCloseChange = (e) => {
    this.setState({
      closeDate: e.target.value
    })
  }

  handleSubmit = async (e) => {
    e.preventDefault()
    console.log(this.state.status, this.props.status)
    if (this.state.status !== this.props.status) {

      console.log("tejas")
      if (this.props.details.status === "upcoming") {
        for (var i = this.props.details.position; i < this.props.upcoming.length; i++) {
          this.props.Updateposition(this.props.upcoming[i].id, this.props.upcoming[i].position - 1)

        }

      }
      if (this.props.details.status === "ongoing") {
        for (var i = this.props.details.position; i < this.props.ongoing.length; i++) {
          this.props.Updateposition(this.props.ongoing[i].id, this.props.ongoing[i].position - 1)

        }

      }
      if (this.props.details.status === "recent") {
        for (var i = this.props.details.position; i < this.props.recent.length; i++) {
          this.props.Updateposition(this.props.recent[i].id, this.props.recent[i].position - 1)

        }



      }

    }
    const { id, ...rest } = this.state
    try {

      const res = await axios.patch(`${process.env.REACT_APP_APIURL}/api/ipo/${id}/update`,
        rest,
        {
          headers: {
            'Authorization': `Bearer ${this.context.user.token}`
          }
        }

      )
      console.log(res)
      this.props.fetchIPO()
      this.props.onHide()

    } catch (error) {
      console.log(error)

    }


  }

  convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  uploadImage = async (e) => {
    const file = e.target.files[0];
    const base64 = await this.convertBase64(file);
    this.setState({ logo: base64 })
  };
  uploadPdf = async (e) => {
    const file = e.target.files[0];
    const base64 = await this.convertBase64(file);
    this.setState({ pdf: base64 })
  }
  render() {
    const { name, position, pdf, video, img, status, upcoming, ongoing, recent, startDate, closeDate, IssueSize, IssueSizeCr, lotSize } = this.state
    return (

      <Modal
        {...this.props}
        size="md"
        // className="modal-content"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Edit IPO</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>
          <Form style={{ alignItems: "left" }} onSubmit={this.handleSubmit}>
            <Form.Group as={Row} className="mb-3" style={{ textAlign: "left" }}>
              <Form.Label column sm="5">Name of the Company</Form.Label>
              <Col sm="7">
                <Form.Control
                  type="text"
                  placeholder="Enter Name of the Company"
                  required
                  name="name"
                  value={name}
                  onChange={this.handleInputChange}
                />
              </Col>

            </Form.Group>

            <Form.Group as={Row} className="mb-3" style={{ textAlign: "left" }}>
              <Form.Label column sm="5">Open Date</Form.Label>
              <Col sm="7">
                <input type="date"
                  // selected={this.state.startDate}
                  onChange={this.handleOpenChange}
                  name="startDate"
                // value={startDate}
                // dateFormat="dd/MM/yyyy"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" style={{ textAlign: "left" }}>
              <Form.Label column sm="5">Close Date</Form.Label>
              <Col sm="7">

                <input type="date"
                  // minDate={new Date(this.state.startDate)}
                  // selected={this.state.closeDate}
                  // min={this.formatISOLocal()}
                  onChange={this.handleCloseChange}
                  name="closeDate"
                  dateFormat="dd/MM/yyyy"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" style={{ textAlign: "left" }}>
              <Form.Label column sm="5">Issue Size</Form.Label>
              <Col sm="7">
                <Form.Control
                  type="text"
                  placeholder="Enter Issue Size"
                  required
                  value={IssueSize}
                  name="IssueSize"
                  onChange={this.handleInputChange}
                />
              </Col>

            </Form.Group>
            <Form.Group as={Row} className="mb-3" style={{ textAlign: "left" }}>
              <Form.Label column sm="5">Issue Size(Cr)</Form.Label>
              <Col sm="7">
                <Form.Control
                  type="text"
                  placeholder="Enter Issue Size(Cr)"
                  required
                  value={IssueSizeCr}
                  name="IssueSizeCr"
                  onChange={this.handleInputChange}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" style={{ textAlign: "left" }}>
              <Form.Label column sm="5">Lot Size</Form.Label>
              <Col sm="7">
                <Form.Control
                  type="text"
                  placeholder="Enter Lot Size"
                  required
                  value={lotSize}
                  name="lotSize"
                  onChange={this.handleInputChange}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" style={{ textAlign: "left" }}>
              <Form.Label column sm="5">Position</Form.Label>
              <Col sm="7">
                <Form.Control
                  type="text"
                  placeholder="Enter position"
                  required
                  value={position}
                  name="position"
                  onChange={this.handleInputChange}
                />
              </Col>

            </Form.Group>
            <Form.Group as={Row} className="mb-3" style={{ textAlign: "left" }}>
              <Form.Label column sm="5">PDF link</Form.Label>
              <Col sm="7">
                <Form.Control
                  type="file"
                  placeholder="Upload PDF"
                  accept="pdf/*"
                  onChange={(e) => {
                    this.uploadPdf(e);
                  }}
                  id="pdf"
                // required
                />
              </Col>

            </Form.Group>
            <Form.Group as={Row} className="mb-3" style={{ textAlign: "left" }}>
              <Form.Label column sm="5">Video link</Form.Label>
              <Col sm="7">
                <Form.Control
                  type="text"
                  placeholder="Enter Youtube Video link"
                  value={video}
                  name="video"
                  onChange={this.handleInputChange}

                />
              </Col>
            </Form.Group>
            <Form.Group className="mb-3" style={{ textAlign: "left" }}>
              <Form.Label>Company Logo</Form.Label>
              <Form.Control
                type="file"
                placeholder="Upload Company Logo"
                accept="image/*"
                onChange={(e) => {
                  this.uploadImage(e);
                }}
                id="imgBox"
              // required
              />
            </Form.Group>

            <Form.Group style={{ textAlign: "left" }} className="form-radios mb-3" required >
              <div className="create-radio">
                <Form.Check type="radio" id="upcoming" name="status" value="upcoming" onChange={this.handleInputChange} />
                <Form.Label>Upcoming</Form.Label>
              </div>
              <div>
                <Form.Check type="radio" id="ongoing" name="status" value="ongoing" onChange={this.handleInputChange} />
                <Form.Label>Ongoing</Form.Label>
              </div>
              <div>
                <Form.Check type="radio" id="recent" name="status" value="recent" onChange={this.handleInputChange} />
                <Form.Label>Recent</Form.Label>
              </div>
            </Form.Group>
            <Button variant="contained" color="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>



    )
  }
}


