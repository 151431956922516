import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React, { useState, useEffect } from 'react';

const ShriramModal = ({ data, showModal, closeModal, depositType, tableName, addNewRow }) => {
  const [editField, setEditField] = useState({})
  // const [show, setShow] = useState(showModal);
  const handleClose = () => closeModal(false);
  // const handleShow = () => setShow(true);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setEditField(data)
  }, [])

  const submitData = async () => {
    setLoading(true)

    const res = await fetch(`${process.env.REACT_APP_APIURL}/rates/shriram/`, {
      method: addNewRow ? "POST" : "PUT",
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify(editField)
    })
    setLoading(false)
    if (res.ok) {
      closeModal(false)
    } else {
      alert("unable to save please try again")
    }

  }

  const deleteRow = async () => {
    setLoading(true)
    // {"id":1,"tenure":"15","cumulative":"6.40","monthly":"6.22","quarterly":"6.25","halfyearly":"6.40","annual":"6.40"}
    const res = await fetch(`${process.env.REACT_APP_APIURL}/rates/shriram/?id=${editField.id}`, {
      method: "DELETE",
    })

    if (res.ok) {
      console.log("deleted")
      closeModal(false)
    } else {
      alert("Please try again")
    }
    setLoading(false)
  }

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{addNewRow ? "adding new row " : "Editing row "}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div class="form-floating  mb-2 ">
            <input style={{ cursor: "not-allowed" }} type="text" onChange={(e) => {
              setEditField((prev) => ({ ...prev, deposit_type: e.target.value }))
            }} disabled value={editField.deposit_type} class="form-control" id="depositType" />
            <label for="depositType ">deposit Type</label>
          </div>

          <div class="form-floating mb-2">
            <input type="text" onChange={(e) => {
              setEditField((prev) => ({ ...prev, period: e.target.value }))
            }} value={editField.period} class="form-control" id="period" />
            <label for="period">period</label>
          </div>

          <div class="form-floating mb-2">
            <input type="text" onChange={(e) => {
              setEditField((prev) => ({ ...prev, monthly: e.target.value }))
            }} value={editField.monthly} class="form-control" id="monthly" />
            <label for="monthly ">monthly</label>
          </div>
          <div class="form-floating mb-2">
            <input type="text" onChange={(e) => {
              setEditField((prev) => ({ ...prev, quarterly: e.target.value }))
            }} value={editField.quarterly} class="form-control" id="quarterly" />
            <label for="quarterly ">quarterly</label>
          </div>
          <div class="form-floating mb-2">
            <input type="text" onChange={(e) => {
              setEditField((prev) => ({ ...prev, half_yearly: e.target.value }))
            }} value={editField.half_yearly} class="form-control" id="halfyearly" />
            <label for="halfyearly ">half yearly</label>
          </div>
          <div class="form-floating mb-2">
            <input type="text" onChange={(e) => {
              setEditField((prev) => ({ ...prev, yearly: e.target.value }))
            }} value={editField.yearly} class="form-control" id="yearly" />
            <label for="yearly">yearly</label>
          </div>
          <div class="form-floating mb-2">
            <input onChange={(e) => {
              setEditField((prev) => ({ ...prev, effective_yield: e.target.value }))
            }} type="text" value={editField.effective_yield} class="form-control" id="effectiveYield" />
            <label for="effectiveYield ">Effective Yield</label>
          </div>
          <div class="form-floating mb-2">
            <input onChange={(e) => {
              setEditField((prev) => ({ ...prev, maturity: e.target.value }))
            }} type="text" value={editField.maturity} class="form-control" id="maturity" />
            <label for="maturity ">maturity</label>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={loading} variant={loading ? "success" : "primary"} onClick={submitData}>
            {loading ? "processing request..." : "save"}
          </Button>
          {
            !addNewRow && <Button disabled={loading} variant="danger" onClick={deleteRow}>
              {loading ? "processing request..." : "delete"}
            </Button>
          }

        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ShriramModal